import { useMediaQuery } from "react-responsive";
import VoyagesProcess from "../components/desk/VoyagesProcess";
import MobVoyagesProcess from "../components/mobile/VoyagesProcess";
import TabVoyagesProcess from "../components/tab/VoyagesProcess";
import VoyageList from "../components/common/VoyageList";
import FareSummary from "./FareSummary";
import { Outlet, useLocation } from "react-router-dom";
import { ProcessContext } from "../components/ProcessContext";
import { useRef, useState } from "react";
export default function Process({ date, setDate, setOpenSearch }) {
  const [travelDate, setTravelDate] = useState("");
  const [returnTravelDate, setReturnTravelDate] = useState("");
  const [showSearchBtn, setShowSearchBtn] = useState(true);
  const [process, setProcess] = useState({
    processSecond: "",
    processThird: "",
  });

  const isMob = useMediaQuery({ query: "(max-width:768px)" });
  const isTab = useMediaQuery({ minWidth: 769, maxWidth: 1279 });
  const isDesk = useMediaQuery({ query: "(min-width: 1280px)" });
  return (
    <div>
      <ProcessContext.Provider
        value={{
          setProcess,
          process,
          travelDate,
          setTravelDate,
          returnTravelDate,
          setReturnTravelDate,
          date,
          setDate,
          setShowSearchBtn,
          showSearchBtn,
        }}
      >
        {isMob && <MobVoyagesProcess setOpenSearch={setOpenSearch} />}
        {isTab && <TabVoyagesProcess setOpenSearch={setOpenSearch} />}
        {isDesk && <VoyagesProcess setOpenSearch={setOpenSearch} />}
        {/* <VoyageList /> */}
        {/* <FareSummary /> */}
        <Outlet />
      </ProcessContext.Provider>
    </div>
  );
}
