import { useState } from "react";
import Loader from "../icons/Loader";
import { fetchApi } from "../../api/utils";
export default function RemovePaxMeal({ setPaxList, setShowRemovPax, setTotalMeal, paxList, type, filterMeals, setShow, chargeCurrency, setShowPaxList, totalMeal }) {
  //   const [submit, setSubmit] = useState(false);
  console.log(type);

  return (
    <div className="p-c p-1 br-r-1" style={{ maxWidth: "900px", maxHeight: "90%", height: "fit-content", overflow: "scroll" }}>
      <div className="bg-white p-4 br-r-1" style={{ height: "fit-content" }}>
        <h1 className="fs-20px  primary">Preferred Meals</h1>
        {/* <p className="gray mtb-1 b-500">Choose your preferred meal for a more enjoyable and personalized travel experience.</p> */}
        <div className="flex justify-end items-center">
          <p className="fs-1-5 mr-4">Total :</p>
          <div className="p-r br-r-12 p-1" style={{ background: "var(--inputGray)", width: "fit-content", minWidth: "121px", height: "68px" }}>
            {/* <span className="b-500 fs-1-5">{submit ? <Loader color="#1F3167" /> : (totalMeal.amount + totalMeal.returnAmount).toLocaleString("en-GB", { style: "currency", currency: chargeCurrency || "LKR" })}</span> */}
            <span className="b-500 fs-1-5">{(totalMeal.amount + totalMeal.returnAmount).toLocaleString("en-GB", { style: "currency", currency: chargeCurrency || "LKR" })}</span>
          </div>
        </div>
        <div className="flex justify-end pr-2 mt-4 mb-2">
          <p className="text-center font-medium" style={{ width: "144px" }}>
            {/* {data.oneWayData.voyageFrom} - {data.oneWayData.voyageTo} */}
          </p>
        </div>
        <div className="mb-2 flex flex-col">
          {paxList.map((pax, index) => {
            if (pax[type]) {
              return <LuggagePerPax type={type} setPaxList={setPaxList} setTotalMeal={setTotalMeal} filterMeals={filterMeals} setShowPaxList={setShowPaxList} setShow={setShow} key={index} pax={pax} />;
            }
          })}
          {/* {loader && <Loader color="#1F3167" />} */}
        </div>

        <div className="flex flex-end gap-x-4">
          <button onClick={() => setShowRemovPax("")} className="btn pointer" style={{ width: "171px" }}>
            Close
          </button>
          {/* <button onClick={handleSubmit} className="p-r btn pointer" style={{ opacity: `${validateErr.oneWay || validateErr.return || submit ? 0.4 : 1}` }} disabled={validateErr.oneWay || validateErr.return}>
            Confirm
          </button> */}
          {/* <button className="btn pointer">Close</button> */}
        </div>
      </div>
    </div>
  );
}

function LuggagePerPax({ type, filterMeals, pax, setPaxList, setTotalMeal }) {
  const [submit, setSubmit] = useState(false);
  function handleConfirm(pax) {
    console.log(pax);
    setSubmit(true);
    setTimeout(() => {
      fetchApi("/Menu/book-food", {
        body: {
          food: type === "food" ? [] : [] || pax.food,
          returnFood: type === "returnFood" ? [] : [] || pax.returnFood,
          paxNumber: {
            companyCode: pax.paxNumber.companyCode,
            documentCode: pax.paxNumber.documentCode,
            bookingYear: pax.paxNumber.bookingYear,
            bookingSerial: pax.paxNumber.bookingSerial,
            seqNo: pax.paxNumber.seqNo,
          },
        },
        requestType: "string",
      }).then((data) => {
        if (data) {
          // setTotalMeal({ amount: data.totalMeal.amount, returnAmount: data.totalMeal.returnAmount });
          fetchApi("/Menu/get-food", {
            body: {
              companyCode: pax.paxNumber.companyCode,
              documentCode: pax.paxNumber.documentCode,
              bookingYear: pax.paxNumber.bookingYear,
              bookingSerial: pax.paxNumber.bookingSerial,
            },
          }).then((data) => {
            if (data) {
              console.log(data);
              setPaxList(data.paxFoodList);
              setTotalMeal(data.totalMeal);
              // setLoader(false);
              filterMeals(pax);
            }
          });
        }
      });
      setSubmit(false);
    }, 2000);
  }
  const [validateErr, setValidateErr] = useState({ weight: "", returnWeight: "" });
  return (
    <>
      <p className="font-medium md-hidden overflow-hidden mb-2"> {pax.name || pax.paxNumber.seqNo}</p>
      <div className={`flex br-r-8 p-2 pl-4 justify-end md-justify-between items-center ${validateErr.weight || validateErr.returnWeight ? "mb-0" : "mb-4"}`} style={{ background: "var(--inputGray)" }}>
        <p className="font-medium hidden md-block overflow-hidden whitespace-nowrap mr-4"> {pax.name || pax.paxNumber.seqNo}</p>
        <div className="flex">
          <button
            onClick={() => handleConfirm(pax)}
            className={"p-r bg-primary br-n white pointer mealAddBtn"}
            style={{
              width: "144px",
              height: "40px",
              borderRadius: "24px",
            }}
          >
            remove{submit && <Loader color="white" />}
          </button>
        </div>
      </div>
      <p className="red text-end text-xs pr-4">{validateErr.weight || validateErr.returnWeight}</p>
    </>
  );
}
