import { postData } from "./utils";
// export async function booking(params) {
//     const url = process.env.REACT_APP_DOAMIN + '/booking/booking';
//     const data = await postData(url, params);
//     if (data) return data;
//     return '';

// }
export async function changeBooking(params) {
  const url = process.env.REACT_APP_DOAMIN + "/DateChangeBooking/DateChangeCharge";
  const data = await postData(url, params);
  if (data) return data;
  return "";
}
export async function reserve(params) {
  const url = process.env.REACT_APP_DOAMIN + "/booking/Reserving";
  const data = await postData(url, params);
  if (data) return data;
  return "";
}
export async function continueBooking(params) {
  const url = process.env.REACT_APP_DOAMIN + "/booking/continueBooking";
  const data = await postData(url, params);
  if (data) return data;
  return "";
}
