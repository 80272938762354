import warningImg from "../../assets/images/warning.png";
export default function Msg({ setMsg, maxMealsCount }) {
  return (
    <div className="overlay">
      <div className="p-c p-1" style={{ width: "fit-content", height: "fit-content" }}>
        <div className="p-1 br-r-8 bg-white">
          <div className="txt-a-c">
            <img src={warningImg} alt="" width="80px" />
          </div>
          <div className="txt-a-c">
            <p className="primary">Maximum Meal Types Reached!</p>
            <p className="gray">Your ticket has reached the allocated maximum meals selections:({maxMealsCount}) for passengers.</p>
            <button className="pointer btn mt-1" onClick={() => setMsg(false)} style={{ width: "120px" }}>
              Continue
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
