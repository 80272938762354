import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { getSearchDetails } from "../../api/search";
import upImg from "../../assets/images/up.png";
import downImg from "../../assets/images/down.png";
import calender from "../../assets/images/calender.png";
import Loader from "../icons/Loader";
import { getCurrency } from "../../api/utils";
export default function SearchVoyages({ searchedParams }) {
  const { state } = useLocation();
  searchedParams = searchedParams ? searchedParams : state;
  const navigate = useNavigate();
  const [dropDownPax, setDropDownPax] = useState(false);
  const [port, setPort] = useState([]);
  const [classes, setClasses] = useState([]);
  const [loader, setLoader] = useState(false);
  const [fields, setFields] = useState({
    type: searchedParams ? searchedParams.type : "one",
    bookFor: searchedParams ? searchedParams.bookFor : "",
    fromPort: searchedParams ? searchedParams.fromPort : "",
    toPort: searchedParams ? searchedParams.toPort : "",
    depatureDate: searchedParams ? searchedParams.depatureDate.split("/").reverse().join("-") : "",
    returnDate: searchedParams ? searchedParams.returnDate.split("/").reverse().join("-") : "",
    classType: searchedParams ? searchedParams.classType : "",
    noOfPax: {
      adult: 1,
      child: 0,
      infant: 0,
    },
    currency: "",
  });
  useEffect(() => {
    setLoader(true);
    if (!localStorage.getItem("chargeCurrency")) {
      getCurrency().then((data) => {
        if (data.currency == "LKR" || data.currency == "INR") {
          localStorage.setItem("chargeCurrency", data.currency);
        } else {
          localStorage.setItem("chargeCurrency", "USD");
        }
      });
    }
    getSearchDetails().then((data) => {
      if (data) {
        let fromPortId = data.ports[0].portId;
        let toPortId = data.ports[1].portId;
        const currency = localStorage.getItem("chargeCurrency");
        if (currency == "LKR") {
          fromPortId = data.ports[0].portId;
          toPortId = data.ports[1].portId;
        } else if (currency == "INR") {
          fromPortId = data.ports[1].portId;
          toPortId = data.ports[0].portId;
        }
        setFields({
          ...fields,
          fromPort: searchedParams ? searchedParams.fromPort : fromPortId,
          toPort: searchedParams ? searchedParams.toPort : toPortId,
          classType: searchedParams ? searchedParams.classType : data.classes[0].classId,
        });
        setPort(data.ports);
        setClasses(data.classes);
        setLoader(false);
      }
    });
  }, []);
  function handleSubmit(e) {
    e.preventDefault();
    localStorage.removeItem("bookingId");
    fields.depatureDate = new Date(fields.depatureDate).toLocaleDateString("en-GB");
    if (fields.returnDate) fields.returnDate = new Date(fields.returnDate).toLocaleDateString("en-GB");
    navigate("/voyages", { state: { fields } });
    if (searchedParams) window.location.reload();
  }
  function handleChange(e) {
    let value = e.target.value;
    setFields({ ...fields, [e.target.name]: value });
  }
  function depatureDate(date) {
    const d = new Date(date);
    d.setDate(d.getDate() + 1);
    return d.toLocaleDateString("en-GB").split("/").reverse().join("-");
  }
  return (
    <div className="fade p-r sm-shadow-n sm-br-r-n sm-p-0" id="search-voyages">
      <form onSubmit={handleSubmit}>
        <div className="mb-2 sm-mb-1-5" style={{ display: "flex", justifyContent: "space-between" }}>
          <div className="sm-m-a">
            <h1 className="fs-20px sm-txt-c b-500 primary smd-fs-1-5">Search voyages</h1>
            <h2 className="sm-fs-14px sm-txt-c" style={{ color: "var(--gray)" }}>
              Let’s sail, book now!
            </h2>
          </div>
        </div>
        <div className="sm-f-j-c mb-1-5 sm-m-b-1rem" style={{ display: "flex" }}>
          <div className="d-f f-a-c mr-2">
            <input onChange={handleChange} style={{ width: "20px", height: "20px", accentColor: "var(--primaryColor)" }} id="one-way" type="radio" name="type" className="mr-1" value="one" checked={fields.type == "one"} />
            <label htmlFor="one-way" className="sm-fs-14px">
              One way
            </label>
          </div>
          <div className="d-f f-a-c">
            <input onChange={handleChange} style={{ width: "20px", height: "20px", accentColor: "var(--primaryColor)" }} id="round-trip" className="mr-1" type="radio" name="type" value="two" checked={fields.type == "two"} />
            <label htmlFor="round-trip" className="sm-fs-14px">
              Round Trip
            </label>
          </div>
        </div>
        <div className="f-col r-gap-1 md-row-gap-1-5" style={{ display: "flex" }}>
          {(localStorage.getItem("role") == "2" || localStorage.getItem("role") == "3") && (
            <div>
              <label htmlFor="" className="search-label sm-fs-12px">
                Book for
              </label>
              <br />
              <input onChange={handleChange} name="bookFor" style={{ width: "100%" }} className="md-m-t search-input" placeholder="give a short name" value={fields.bookFor} maxLength={30} required />
            </div>
          )}
          <div className="search-input-wrapper f-spb " style={{ display: "flex" }}>
            <PortDropDown label="From" value={fields.fromPort} data={port} name="fromPort" handleChange={handleChange} />
            <PortDropDown label="To" value={fields.toPort} data={port} name="toPort" handleChange={handleChange} />
          </div>
          <div className="d-f w-full c-gap-1 f-spb search-input-wrapper">
            <TravelDate value={fields.depatureDate} minDate={false} label="Departure" name="depatureDate" handleChange={handleChange} />
            <TravelDate value={fields.returnDate} minDate={depatureDate(fields.depatureDate)} label="Return" name="returnDate" handleChange={handleChange} disabled={fields.type == "one"} />
          </div>
          <div className="search-input-wrapper f-spb " style={{ display: "flex" }}>
            <div className="p-r">
              <label htmlFor="date" className="search-label sm-fs-12px">
                No of Passengers
              </label>
              <br />

              <p
                className="search-input pax-drop-bg d-f f-a-c"
                onClick={() => {
                  setDropDownPax(!dropDownPax);
                }}
                style={{ padding: "0 4px 0 1rem", backgroundImage: dropDownPax ? `url(${upImg})` : `url(${downImg})` }}
              >
                AD:{fields.noOfPax.adult}&nbsp; CH:{fields.noOfPax.child}&nbsp; IN:{fields.noOfPax.infant}
              </p>
              <NoOfPax fields={fields} drop={dropDownPax} searchedParams={searchedParams} />
              {dropDownPax && <div style={{ width: "100%", height: "100%", position: "fixed", top: 0, left: 0 }} onClick={() => setDropDownPax(false)}></div>}
            </div>
            <div>
              <label htmlFor="" className="search-label sm-fs-12px">
                Class
              </label>
              <br />
              <select onChange={handleChange} className="search-input" name="classType" value={fields.classType} required>
                {classes.length &&
                  classes.map((classes, index) => (
                    <option key={index} value={classes.classId}>
                      {classes.className}
                    </option>
                  ))}
              </select>
            </div>
          </div>
        </div>
        <p className="fs-12px txt-a-c mt-1">*Subject to weather conditions clearance from authorities</p>
        <div className="mt-1 sm-mt-1-5" style={{ textAlign: "center" }}>
          <button id="search-btn" className="p-r white search-input b-500" type="submit" value="Search" style={{ background: "var(--primaryColor)", cursor: "pointer" }}>
            Search
          </button>
        </div>
      </form>
      {loader && (
        <div className="overlay">
          <Loader />
        </div>
      )}
    </div>
  );
}
function PortDropDown({ value, label, data, name, handleChange }) {
  return (
    <div>
      <label htmlFor="from" className="search-label sm-fs-12px">
        {label}
      </label>
      <br />
      <select value={value} onChange={handleChange} className="md-m-t search-input" name={name} required>
        {data.length &&
          data.map((port, index) => (
            <option key={index} value={port.portId}>
              {port.portName}
            </option>
          ))}
      </select>
    </div>
  );
}
function TravelDate({ minDate, value, label, handleChange, name, disabled = false }) {
  let currentDate = new Date().toJSON().slice(0, 10);
  return (
    <div>
      <label htmlFor="date" className="search-label sm-fs-12px">
        {label}
      </label>
      <br />
      {/* <input value={value} min={minDate ? minDate : currentDate} className="search-input" type="date" name={name} onChange={handleChange} disabled={disabled} required /> */}
      <div className="p-r">
        <input className="search-input pax-drop-bg" type="text" style={{ paddingRight: "8px", backgroundImage: `url(${calender})`, opacity: disabled ? 0.4 : 1 }} value={value ? value.split("-").reverse().join("/") : "DD/MM/YYYY"} readOnly />
        {!disabled && <input value={value} min={minDate ? minDate : currentDate} style={{ opacity: 0, border: "none", color: "var(--primaryColor)", backgroundColor: "none", width: "30px", height: "30px", top: 0, bottom: 0, margin: "auto", position: "absolute", right: "8px" }} type="date" name={name} onChange={handleChange} required />}
      </div>
    </div>
  );
}
function NoOfPax({ fields, drop, searchedParams }) {
  const [count, setCount] = useState({
    adult: searchedParams ? searchedParams.noOfPax.adult : 1,
    child: searchedParams ? searchedParams.noOfPax.child : 0,
    infant: searchedParams ? searchedParams.noOfPax.infant : 0,
  });
  function increment(e, i) {
    if (count[e.target.name] + 1 >= 0) {
      setCount({ ...count, [e.target.name]: count[e.target.name] + i });
    }
  }
  function decrement(e, i) {
    if (count[e.target.name] - 1 >= 0) {
      if (e.target.name == "adult" && count[e.target.name] - 1 == 0) setCount({ ...count, [e.target.name]: 1 });
      else setCount({ ...count, [e.target.name]: count[e.target.name] - i });
    }
  }
  fields.noOfPax = count;
  const desOfPax = [
    {
      name: "adult",
      label: "Adult",
      des: "More than 12 Years",
    },
    {
      name: "child",
      label: "Child",
      des: "2 - 12 Years",
    },
    {
      name: "infant",
      label: "Infant",
      des: "Less than 2 Years",
    },
  ];
  const display = drop ? "flex" : "none";
  return (
    <div className="fade d-f f-col f-spb p-a shadow sm-w-full" style={{ display: display, height: "202px", width: "196px", background: "white", padding: "13px 24px", top: "72px", zIndex: 1, borderRadius: "0px 0px 8px 8px" }}>
      {desOfPax.map((item, index) => (
        <div key={index}>
          <div className="d-f f-spb">
            <p className="fs-14px">{item.label}</p>
            <div className="d-f f-a-c">
              <input name={item.name} className="round-btn pointer fs-14px" type="button" value="-" onClick={(e) => decrement(e, 1)} />
              <span className="fs-14px txt-a-c" style={{ width: "30px", display: "inline-block" }}>
                {count[item.name]}
              </span>
              <input name={item.name} className="round-btn pointer fs-14px" type="button" value="+" onClick={(e) => increment(e, 1)} />
            </div>
          </div>
          <p className="fs-10px">{item.des}</p>
        </div>
      ))}
    </div>
  );
}
