import { useMediaQuery } from "react-responsive";
import { useLocation, useNavigate } from "react-router-dom";
import YourVoyages from "../components/desk/YourVoyages";
import MobYourVoyages from "../components/mobile/YourVoyages";
import TabYourVoyages from "../components/tab/YourVoyages";
import Loader from "../components/icons/Loader";
import { reserve } from "../api/book";
import { useEffect, useState, useContext } from "react";
import { ProcessContext, Roundcontext } from "../components/ProcessContext";
import PassengerDts from "../components/common/PassengerDts";
import LuggageSelection from "../components/luggage/LuggageSelection";
import MealSelection from "../components/meal/MealSelection";
import successImg from "../assets/images/success.png";
import failImg from "../assets/images/fail.png";
import warningImg from "../assets/images/warning.png";
import { cancelReserving, getCreditLimit, creditReserve, getPayment, makePayment } from "../api/api";
import { fetchApi } from "../api/utils";
import QRCode from "react-qr-code";
export default function FareSummary() {
  const value = useContext(ProcessContext);
  const navigate = useNavigate();
  const { state } = useLocation();
  const [msg, setMsg] = useState({
    status: "success",
    message: "",
    alertMessage: "",
  });
  const [loader, setLoader] = useState(false);
  const [confirm, setConfirm] = useState(localStorage.getItem("bookingId") ? true : false);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [paxSuccess, setPaxSuccess] = useState(false);
  const [proceedPaymentDts, setProceedPaymentDts] = useState({
    bookingId: localStorage.getItem("bookingId") ? JSON.parse(localStorage.getItem("bookingId")) : [],
    food: [],
    returnFood: [],
    weight: 0,
    returnWeight: 0,
    paymentAmount: 0,
  });
  const [completedPaxes, setCompletedPaxes] = useState([]);
  const [totalExcessBuggage, setTotalExcessBuggage] = useState({
    weight: 0,
    amount: 0,
    returnWeight: 0,
    returnAmount: 0,
  });
  const [totalMeal, setTotalMeal] = useState({
    amount: 0,
    returnAmount: 0,
  });
  const [creditMsg, setCreditMsg] = useState(false);
  const [creditLimit, setCreditLimit] = useState(0);
  const [isCredit, setIsCredit] = useState(false);
  const [paymentOptions, setPaymentOptions] = useState(false);
  const [qrCodeModal, setQrCodeModal] = useState(false);
  const [qrCode, setQrCode] = useState({
    url: "",
    amount: 0,
  });
  const [otherMsg, setOtherMsg] = useState(false);
  const [openFreeBaggage, setOpenFreeBaggage] = useState(true);
  const freeBaggage = 0;
  let book = "";
  let travelDate = "";
  if (state) {
    book = state.book;
    travelDate = state.travelDate.split("-").reverse().join("/");
  } else {
    book = JSON.parse(localStorage.getItem("book"));
    travelDate = localStorage.getItem("travelDate");
  }
  useEffect(() => {
    if (localStorage.getItem("token")) {
      getCreditLimit({ bookingId: proceedPaymentDts.bookingId }).then((data) => {
        if (data) {
          setCreditLimit(data.creditLimit);
          setIsCredit(data.isCredit);
        }
      });
    }
    if (value.process.processSecond) {
      value.process.processSecond.style.color = "white";
      value.process.processSecond.style.backgroundColor = "#DF7435";
    }

    if (state) {
      value.setTravelDate(state.travelDate);
      value.setReturnTravelDate(state.returnTravelDate);
    }
    setProceedPaymentDts({
      ...proceedPaymentDts,
      bookingId: book.bookingId ? book.bookingId : JSON.parse(localStorage.getItem("bookingId")),
      paymentAmount: book.oneWayData.totalChargeAmount + (book.roundData ? book.roundData.totalChargeAmount : 0),
    });
    if (book.isReservation) setConfirm(true);
    value.setDate({
      dateOfTravel: book.oneWayData.dateOfTravel,
      voyageFrom: book.oneWayData.voyageFrom,
      voyageTo: book.oneWayData.voyageTo,
      returnDateOfTravel: book.roundData ? book.roundData.dateOfTravel : "",
      returnVoyageFrom: book.roundData ? book.roundData.voyageFrom : "",
      returnVoyageTo: book.roundData ? book.roundData.voyageTo : "",
    });
  }, []);
  const isMob = useMediaQuery({ query: "(max-width:768px)" });
  const isTab = useMediaQuery({ minWidth: 769, maxWidth: 1279 });
  const isDesk = useMediaQuery({ query: "(min-width: 1280px)" });

  const reserveData = {
    type: book.type,
    fareCurrency: "",
    bookFor: book.bookFor,
    classType: book.classType,
    noOfPax: book.noOfPax,

    voyageId: book.voyageId,
    ferryId: book.ferryId,
    voyageYear: book.voyageYear,
    fareAmount: book.oneWayData.fareAmount,
    taxAmountInFareCurrency: book.oneWayData.taxAmountInFareCurrency,
    totalChargeAmount: book.oneWayData.totalChargeAmount,
    totalFareAmount: book.oneWayData.totalFareAmount,
    adultPrice: book.oneWayData.adultPrice,
    childPrice: book.oneWayData.childPrice,
    infantPrice: book.oneWayData.infantPrice,

    returnVoyageId: book.type == "two" ? book.returnVoyageId : "",
    returnVoyageYear: book.type == "two" ? book.returnVoyageYear : 0,
    returnFerryId: book.type == "two" ? book.returnFerryId : "",
    returnFareAmount: book.type == "two" ? book.roundData.fareAmount : 0,
    returnTaxAmountInFareCurrency: book.type == "two" ? book.roundData.taxAmountInFareCurrency : 0,
    returntotalFareAmount: book.type == "two" ? book.roundData.totalFareAmount : 0,
    returntotalChargeAmount: book.type == "two" ? book.roundData.totalChargeAmount : 0,
    returnAdultPrice: book.type == "two" ? book.roundData.adultPrice : 0,
    returnChildPrice: book.type == "two" ? book.roundData.childPrice : 0,
    returnInfantPrice: book.type == "two" ? book.roundData.infantPrice : 0,
  };

  function SLAgentProceedPayment() {
    async function goTopayment() {
      setLoader(true);
      try {
        const response = await fetch(process.env.REACT_APP_DOAMIN + "/ProceedPayment/CreditPayment", {
          method: "POST",
          headers: {
            "Content-Type": "Application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify(proceedPaymentDts),
        });

        const result = await response.json();
        if (response.status == 200) {
          localStorage.setItem("bookingId", JSON.stringify(proceedPaymentDts.bookingId));
          // if (result.orderId) diplayRazorpay(result.orderId)
          navigate("/invoice", { state: proceedPaymentDts.bookingId });
        } else {
          throw result;
        }
      } catch (err) {
        setMsg({
          status: "failed",
          message: err.message,
          alertMessage: "",
        });
      }
      // getOrderId(proceedPaymentDts).then(
      //     data => {
      //         localStorage.setItem('bookingId', JSON.stringify(proceedPaymentDts.bookingId));
      //         console.log(data)
      //         if (data.orderId) diplayRazorpay(data.orderId)
      //         else navigate('/seat-selection', { state: bookingId })
      //     }
      // )
    }
    if (proceedPaymentDts.paymentAmount > creditLimit) {
      // setMsg({
      //     status: 'failed',
      //     message: "Your credit is insufficient",
      //     alertMessage: "Topup the money and try again"
      // })
      setCreditMsg(true);
      // setTimeout(() => window.location.assign('/dashboard'), 3000)
    } else goTopayment(); //store all precedures in backend
    // navigate('/seat-selection', { state: proceedPaymentDts.bookingId })
  }
  function handleConfirm() {
    setLoader(true);
    reserveData.bookingId = [];
    if (!localStorage.getItem("token")) {
      localStorage.setItem("book", JSON.stringify(book));
      localStorage.setItem("travelDate", state.travelDate.split("-").reverse().join("/"));
      navigate("/");
    } else {
      fetchApi("/booking/booking", { body: reserveData }).then((data) => {
        setLoader(false);
        if (data.errMsg) {
          setMsg({
            status: "",
            message: data.errMsg ? data.errMsg : data.result.message,
            alertMessage: data.errMsg ? "" : data.result.alertMessage,
          });
        } else {
          if (data.errCode) {
            setMsg({
              status: "",
              message: data.result.message,
              alertMessage: data.result.alertMessage,
            });
          } else {
            setMsg({
              status: "success",
              message: data.message,
              alertMessage: data.alertMessage,
            });
            setProceedPaymentDts({ ...proceedPaymentDts, bookingId: data.bookingId });
            localStorage.setItem("bookingId", JSON.stringify(data.bookingId));
            setTimeout(() => {
              setConfirm(true);
            }, 4000);
          }
        }
        setTimeout(() => {
          setMsg({ status: "success", message: "", alertMessage: "" });
        }, 3000);
      });
    }
  }
  function handleReserve() {
    setLoader(true);
    reserve(reserveData).then((data) => {
      if (data) {
        setLoader(false);
        setMsg({ status: "success", message: data.message, alertMessage: data.alertMessage });
        setTimeout(() => {
          setMsg({ msg: "success", message: "", alertMessage: "" });
          setLoader(false);
          navigate("/dashboard");
        }, 3000);
      } else {
        setLoader(false);
        setMsg({ status: "fail", message: "Reservation Failed", alertMessage: "Please Try again" });
        setTimeout(() => {
          setMsg({ status: "fail", message: "", alertMessage: "" });
          setLoader(false);
          navigate("/dashboard");
        }, 3000);
      }
    });
  }
  function handleCreditReserve() {
    setLoader(true);
    creditReserve({ bookingId: proceedPaymentDts.bookingId }).then((data) => {
      if (data) {
        setLoader(false);
        setMsg({ status: "success", message: data.message, alertMessage: data.alertMessage });
        setTimeout(() => {
          setMsg({ msg: "success", message: "", alertMessage: "" });
          setLoader(false);
          navigate("/dashboard");
        }, 3000);
      } else {
        setLoader(false);
        setMsg({ status: "fail", message: "Reservation Failed", alertMessage: "Please Try again" });
        setTimeout(() => {
          setMsg({ status: "fail", message: "", alertMessage: "" });
          setLoader(false);
          navigate("/dashboard");
        }, 3000);
      }
    });
  }
  function handleCancel() {
    setLoader(true);
    cancelReserving({ bookingId: proceedPaymentDts.bookingId }).then((data) => {
      setLoader(false);
      setMsg({ status: "success", message: data.message, alertMessage: "" });
      setTimeout(() => navigate("/dashboard"), 3000);
      localStorage.removeItem("bookingId");
    });
  }
  function showPaymentOptions() {
    if (isCredit) SLAgentProceedPayment();
    else setPaymentOptions(true);
  }
  const style = {
    totalBatch: {
      width: "fit-content",
      height: "68px",
      backgroundColor: "#D9D9D9",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      columnGap: "1rem",
      borderRadius: "12px",
    },
  };

  return (
    <div className="p-1">
      <div className="p-1 sm-m-a" style={style.totalBatch}>
        <span>Total Fare</span>
        {/* <span>{book.oneWayData.chargeCurrency}</span> */}
        <span className="fs-1-5 b-600" style={{ fontFamily: "Arial, sans-serif" }}>
          {(totalMeal.amount + totalMeal.returnAmount + totalExcessBuggage.amount + totalExcessBuggage.returnAmount + proceedPaymentDts.paymentAmount).toLocaleString("en-GB", { style: "currency", currency: book.chargeCurrency })}
        </span>
      </div>
      <p className="mtb-1-5 fs-20px b-500 sm-txt-c">Your voyages</p>
      <div className="d-f f-col f-a-c r-gap-1-5">
        {isMob && <MobYourVoyages book={book.oneWayData} maxLuggage={book.oneWayData.maxLuggage} />}
        {isTab && <TabYourVoyages book={book.oneWayData} maxLuggage={book.oneWayData.maxLuggage} />}
        {isDesk && <YourVoyages book={book.oneWayData} maxLuggage={book.oneWayData.maxLuggage} />}

        {book.round && isMob && <MobYourVoyages book={book.roundData} maxLuggage={book.roundData.maxLuggage} />}
        {book.round && isTab && <TabYourVoyages book={book.roundData} maxLuggage={book.roundData.maxLuggage} />}
        {book.round && isDesk && <YourVoyages book={book.roundData} maxLuggage={book.roundData.maxLuggage} />}
      </div>
      {confirm && (
        <div className="m-a mt-2" style={{ maxWidth: "1080px" }}>
          <PassengerDts type={book.type} toPort={book.oneWayData.voyageTo} bookingId={JSON.parse(localStorage.getItem("bookingId"))} setPaxSuccess={setPaxSuccess} />
        </div>
      )}
      {confirm && (
        <div className="mt-2 r-gap-8 d-f f-wrap f-spb m-a" style={{ maxWidth: "1080px" }}>
          {/* <SeatSelection /> */}
          <LuggageSelection completedPaxes={completedPaxes} setCompletedPaxes={setCompletedPaxes} totalExcessBuggage={totalExcessBuggage} setTotalExcessBuggage={setTotalExcessBuggage} data={book} setProceedPaymentDts={setProceedPaymentDts} proceedPaymentDts={proceedPaymentDts} />
          <Roundcontext.Provider value={book.round}>
            <MealSelection completedPaxes={completedPaxes} setCompletedPaxes={setCompletedPaxes} totalMeal={totalMeal} setTotalMeal={setTotalMeal} data={book} travelDate={travelDate} setProceedPaymentDts={setProceedPaymentDts} proceedPaymentDts={proceedPaymentDts} />
          </Roundcontext.Provider>
        </div>
      )}
      <div className="mt-2 d-f f-j-e">
        {/* <div className="d-f f-col">
                    <p className="fs-20px b-500">Total Fare</p>
                    <p className="fs-1-5 b-600">{book.totalFare}</p>
                    <p>Overall Cost for All Passengers (Inclusive of Taxes and Fees)</p>
                    <Link className="black">Fare Details</Link>
                    <Link className="black">Payment Policy</Link>
                    <Link className="black">Conditions of Travel</Link>
                </div> */}
        {!confirm && (
          <div className="d-f sm-f-col sm-m-a c-gap-8 r-gap-1">
            <button onClick={() => navigate("/home")} className="pointer br-btn" style={{ width: "256px", height: "48px" }}>
              Cancel
            </button>
            {(localStorage.getItem("role") == "2" || localStorage.getItem("role") == "3") && (
              <button onClick={handleReserve} className="pointer br-btn primary" style={{ width: "256px", height: "48px", opacity: book.isReservation ? ".4" : 1 }} disabled={book.isReservation ? true : false}>
                Reserve
              </button>
            )}
            <button onClick={handleConfirm} className="pointer btn" style={{ width: "256px", height: "48px" }}>
              Confirm Voyages
            </button>
          </div>
        )}
        {confirm && (
          <div className="d-f c-gap-8 sm-f-col sm-m-a sm-r-gap-1">
            <button onClick={handleCancel} className="pointer br-btn primary" style={{ width: "256px", height: "48px" }}>
              Cancel
            </button>
            <button onClick={() => setPaymentOptions(true)} className="pointer btn" style={{ width: "256px", height: "48px", opacity: !paxSuccess ? 0.4 : 1 }} disabled={!paxSuccess}>
              Proceed to Payment
            </button>
          </div>
        )}
      </div>
      {msg.message && <Success data={msg} setMsg={setMsg} />}
      {creditMsg && <CreditMsg setCreditMsg={setCreditMsg} handleCreditReserve={handleCreditReserve} />}
      {paymentSuccess && <PaymentSuccess bookingId={proceedPaymentDts.bookingId} />}
      {loader && (
        <div className="overlay">
          <Loader />
        </div>
      )}
      {/* {
                showPayment && <div className='overlay'><Payment proceedPayment={!proceedPayment} /></div>
            } */}
      {paymentOptions && <PaymentOptions currency={book.oneWayData.chargeCurrency} proceedPaymentDts={proceedPaymentDts} setPaymentOptions={setPaymentOptions} />}
      {qrCodeModal && <QRcodeModal qrCode={qrCode} setQrCodeModal={setQrCodeModal} />}
      {otherMsg && <OtherMsg />}
      {openFreeBaggage && confirm && <FreeBaggage freeBaggage={freeBaggage} setOpenFreeBaggage={setOpenFreeBaggage} />}
    </div>
  );
}

function Success({ data, setMsg }) {
  return (
    <div className="overlay">
      <div className="p-c p-1 scale" style={{ maxWidth: "500px", height: "fit-content" }}>
        <div className="p-1 br-r-8 bg-white">
          <div className="txt-a-c">
            <img src={data.status == "success" ? successImg : failImg} alt="" width="80px" />
          </div>
          <div className="txt-a-c mt-1">
            <p className="primary b-500">{data.message}</p>
            <p className="gray" style={{ marginTop: ".5rem" }}>
              {data.alertMessage}
            </p>
            {data.status == "failed" && (
              <button className="pointer msg-btn mt-1" onClick={() => window.location.assign("/dashboard")}>
                ok
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
function CreditMsg({ setCreditMsg, handleCreditReserve }) {
  const navigate = useNavigate();
  return (
    <div className="overlay">
      <div className="p-c p-1" style={{ maxWidth: "450px", height: "fit-content" }}>
        <div className="p-1 br-r-8 bg-white">
          <div className="txt-a-c">
            <img src={warningImg} alt="" width="80px" />
          </div>
          <div className="txt-a-c">
            <p className="primary">Your credit is insufficient</p>
            <p>Do you want to reserve?</p>
            {/* <button className='pointer msg-btn mt-1' onClick={() => setMsg({ msg: "", alertMessage: "" })}>ok</button> */}
            <div className="d-f f-j-c c-gap-1 mt-2 pb-1">
              <button
                onClick={() => {
                  setCreditMsg(false);
                  navigate("/dashboard");
                }}
                className="btn pointer"
                style={{ width: "90px", height: "34px" }}
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  setCreditMsg(false);
                  handleCreditReserve();
                }}
                className="br-btn primary pointer"
                style={{ width: "90px", height: "34px" }}
              >
                Reserve
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
function PaymentSuccess({ bookingId }) {
  const navigate = useNavigate();
  return (
    <div className="overlay">
      <div className="sm-malr-1 p-c p-1 br-r-8 bg-white txt-a-c" style={{ width: "300px", height: "fit-content" }}>
        <p className="primary">Payment Successfull</p>
        <button className="pointer msg-btn mt-1" onClick={() => navigate("/seat-selection", { state: bookingId })}>
          ok
        </button>
      </div>
    </div>
  );
}
function PaymentOptions({ currency, setPaymentOptions, proceedPaymentDts }) {
  const [payableAmount, setPayableAmount] = useState(0);
  const [loader, setLoader] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      getPayment({ bookingId: proceedPaymentDts.bookingId }).then((data) => {
        setLoader(false);
        setPayableAmount(data.payableAmount);
      });
    }, 2000);
  }, []);
  function handleConfirm() {
    setLoader(true);
    setTimeout(() => {
      makePayment({ bookingId: proceedPaymentDts.bookingId }).then((data) => {
        // setPayableAmount(data.payableAmount);
        if (data) {
          window.location.assign(data.url);
          // window.open(data.url, "_blank");
        }
      });
    }, 2000);
  }
  return (
    <div className="overlay">
      <div className="p-c p-1 scale" style={{ width: "fit-content", height: "fit-content" }}>
        {loader && <Loader color="white" />}
        {!loader && (
          <div className="bg-white br-r-8 ptb-1 plr-1-5">
            <div className="d-f f-j-e sm-f-j-c">
              <p onClick={() => setPaymentOptions(false)} className="d-f f-a-c f-j-c pointer" style={{ width: "30px", height: "30px", borderRadius: "50px", border: "solid 1px black" }}>
                &times;
              </p>
            </div>
            <div className="flex justify-center items-center mt-4">
              <p className="fs-1-5 mr-4">Total :</p>
              <div className="p-r br-r-12 p-1" style={{ background: "var(--inputGray)", width: "fit-content", minWidth: "121px", height: "60px" }}>
                <span className="b-500 fs-1-5">{false ? <Loader color="#DF7435" /> : payableAmount.toLocaleString("en-GB", { style: "currency", currency: currency ? currency : "lkr" })}</span>
              </div>
            </div>

            <h1 className="txt-a-c b-500 mt-1 primary fs-20px">Confirm and proceed to payment!</h1>
            <p className="txt-a-c gray mt-1 gray">
              You will be redirected to the payment gateway page. <br /> Please complete the payment within 15 minutes.
            </p>
            <div className="d-f mb-1 mt-2 c-gap-8 r-gap-1 f-j-c">
              <button onClick={handleConfirm} className="btn pointer plr-1" style={{ width: "fit-content" }}>
                Confirm & Pay Now
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
function QRcodeModal({ setQrCodeModal, qrCode }) {
  const navigate = useNavigate();
  return (
    <div className="overlay">
      <div className="p-c p-1 scale" style={{ width: "fit-content", height: "fit-content" }}>
        <div className="bg-white br-r-8 ptb-1 plr-1-5">
          <div className="d-f f-j-e sm-f-j-c">
            <p onClick={() => setQrCodeModal(false)} className="d-f f-a-c f-j-c pointer" style={{ width: "30px", height: "30px", borderRadius: "50px", border: "solid 1px black" }}>
              &times;
            </p>
          </div>
          <h1 className="txt-a-c b-500 mt-1">Ready to Pay? Use Your UPI App.</h1>
          <p className="txt-a-c gray">Open your UPI app to scan the QR code or input the code and make the payment.</p>
          <p className="txt-a-c primary mtb-1" style={{ fontFamily: "Arial, sans-serif" }}>
            {qrCode.amount.toLocaleString("en-GB", { style: "currency", currency: localStorage.getItem("chargeCurrency") })}
          </p>
          <div className="d-f mb-1 f-j-c">
            <div className="p-1 br-r-8" style={{ border: "1px solid black" }}>
              <QRCode value={qrCode.url ? qrCode.url : ""} size={256} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
function OtherMsg() {
  return (
    <div className="overlay">
      <div className="p-c p-1 scale" style={{ maxWidth: "500px", height: "fit-content" }}>
        <div className="p-1 br-r-8 bg-white">
          <div className="txt-a-c">
            <img src={failImg} alt="" width="80px" />
          </div>
          <div className="txt-a-c mt-1">
            <p className="primary b-500">Payment cannot be processed!</p>
            <p className="" style={{ marginTop: ".5rem" }}>
              Currently, we are unable to process payments from your region. Please contact an agent to book your tickets. We apologize for the inconvenience.
            </p>
            <p className="" style={{ marginTop: ".5rem" }}>
              Contact our agents <br />
              Email : info@metro.voyage
              <br />
              Phone : 0117642117
              <br />
            </p>
            <button className="pointer msg-btn mt-1" onClick={() => window.location.assign("/home")}>
              ok
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
function FreeBaggage({ freeBaggage, setOpenFreeBaggage }) {
  return (
    <div className="overlay">
      <div className="sm-malr-1 p-c p-1 br-r-8 bg-white txt-a-c" style={{ maxWidth: "500px", height: "fit-content" }}>
        <p className="primary"></p>
        {freeBaggage == 0 ? <p>Please note that no checked in baggage is included in the ticket. If you need to take checked in baggage, you could purchase up to 50 kilos at this time.</p> : <p>Please note that the total checked baggage weight included in the ticket is 50 Kilos only. Additional checked in baggage could be bought at this time up to maximum checked in baggage of 50 kilos</p>}
        <button
          className="pointer msg-btn mt-1"
          onClick={() => {
            setOpenFreeBaggage(false);
          }}
        >
          ok
        </button>
      </div>
    </div>
  );
}
