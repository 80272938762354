import { useMediaQuery } from "react-responsive";
import { Link, useLocation, useNavigate } from "react-router-dom";
import YourVoyages from "../desk/YourVoyages";
import MobYourVoyages from "../mobile/YourVoyages";
import TabYourVoyages from "../tab/YourVoyages";
import Loader from "../icons/Loader";
import { changeBooking, reserve } from "../../api/book";
import { useEffect, useState, useContext } from "react";
import { ChangeDatecontext, Roundcontext } from "../ProcessContext";
import Payment from "./Payment";
import PassengerDts from "./PassengerDts";
import LuggageSelection from "../luggage/LuggageSelection";
import MealSelection from "../meal/MealSelection";
import { verifyPayment } from "../../api/payment";
import logo from "../../assets/images/logo.png";
import successImg from "../../assets/images/success.png";
import failImg from "../../assets/images/fail.png";
import warningImg from "../../assets/images/warning.png";
import { cancelReserving, getCreditLimit, creditReserve } from "../../api/api";
import razorPayImg from "../../assets/images/razorpay.svg";
import hsbcImg from "../../assets/images/hsbc.svg";
import ccAvenuePayImg from "../../assets/images/cc-avenue.svg";
export default function DateChangeFareSummary() {
  const value = useContext(ChangeDatecontext);
  const roundContext = useContext(Roundcontext);
  const navigate = useNavigate();
  const [paymentOptions, setPaymentOptions] = useState(false);
  const { state } = useLocation();
  const [msg, setMsg] = useState({
    status: "success",
    message: "",
    alertMessage: "",
  });
  const [fareDetails, setFareDetails] = useState({
    chargeAmount: 0,
    tax: 0,
    total: 0,
  });
  const [loader, setLoader] = useState(false);
  const [showPayment, setShowPayment] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [bookingId, setBookingId] = useState("");
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [paxSuccess, setPaxSuccess] = useState(false);
  const [proceedPaymentDts, setProceedPaymentDts] = useState({
    bookingId: JSON.parse(localStorage.getItem("changeBookingId")),
    food: [],
    returnFood: [],
    weight: 0,
    returnWeight: 0,
    paymentAmount: 0,
    paymentGateway: 0,
  });
  const [creditMsg, setCreditMsg] = useState(false);
  const [creditLimit, setCreditLimit] = useState(0);
  const [isCredit, setIsCredit] = useState(false);
  const [qrCodeModal, setQrCodeModal] = useState(false);
  const [qrCode, setQrCode] = useState({
    url: "",
    amount: 0,
  });
  let book = "";
  let travelDate = "";
  if (state) {
    book = state.book;
    travelDate = state.travelDate.split("-").reverse().join("/");
  } else {
    book = JSON.parse(localStorage.getItem("book"));
    travelDate = localStorage.getItem("travelDate");
  }
  useEffect(() => {
    if (localStorage.getItem("token")) {
      getCreditLimit({ bookingId: proceedPaymentDts.bookingId }).then((data) => {
        if (data) {
          setCreditLimit(data.creditLimit);
          setIsCredit(data.isCredit);
        }
      });
    }
    if (value.process.processSecond) {
      value.process.processSecond.style.color = "white";
      value.process.processSecond.style.backgroundColor = "#DF7435";
    }

    if (state) {
      value.setTravelDate(state.travelDate);
      value.setReturnTravelDate(state.returnTravelDate);
    }
    value.setShowSearchBtn(false);
    setProceedPaymentDts({
      ...proceedPaymentDts,
      bookingId: book.bookingId ? book.bookingId : JSON.parse(localStorage.getItem("changeBookingId")),
      paymentAmount: book.oneWayData.totalChargeAmount + (book.roundData ? book.roundData.totalChargeAmount : 0),
    });
    if (book.isReservation) setConfirm(true);
    value.setDate({
      dateOfTravel: book.oneWayData.dateOfTravel,
      voyageFrom: book.oneWayData.voyageFrom,
      voyageTo: book.oneWayData.voyageTo,
      returnDateOfTravel: book.roundData ? book.roundData.dateOfTravel : "",
      returnVoyageFrom: book.roundData ? book.roundData.voyageFrom : "",
      returnVoyageTo: book.roundData ? book.roundData.voyageTo : "",
    });
  }, []);
  const isMob = useMediaQuery({ query: "(max-width:768px)" });
  const isTab = useMediaQuery({ minWidth: 769, maxWidth: 1279 });
  const isDesk = useMediaQuery({ query: "(min-width: 1280px)" });

  const changeBookingData = {
    voyageId: book.voyageId,
    ferryId: book.ferryId,
    voyageYear: book.voyageYear,
    returnVoyageId: book.returnVoyageId,
    returnVoyageYear: book.returnVoyageYear,
    returnFerryId: book.returnFerryId,
    paymentGateway: 0,
    bookingId: JSON.parse(localStorage.getItem("changeBookingId")),
  };
  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }
  async function diplayRazorpay(orderId, oldBookingId, newBookingId) {
    const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");

    if (!res) {
      alert("Razorpay sdk failed to load. Are you online ?");
    }

    const dev = (document.domain = "ferry.thaayakam.co.uk");

    const options = {
      key: dev ? "rzp_test_DTUyBEfv1bEPiP" : "PASTE_THE_ACTUAL_KEY", // Enter the Key ID generated from the Dashboard
      amount: "400000", // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      currency: "INR",
      name: "Sail Subham", //your business name
      description: "Test Transaction",
      image: logo,
      order_id: orderId, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
      handler: function (response) {
        // alert(response.razorpay_payment_id);
        // alert(response.razorpay_order_id);
        // alert(response.razorpay_signature);
        alert("new hi success");
        verifyPayment({
          razorpay_payment_id: response.razorpay_payment_id,
          razorpay_order_id: response.razorpay_order_id,
          razorpay_signature: response.razorpay_signature,
          bookingId: proceedPaymentDts.bookingId,
        }).then((data) => {
          if (data) {
            // setPaymentSuccess(true);
            alert("hi success");
            setTimeout(() => navigate("/invoice", { state: proceedPaymentDts.bookingId }), 3000);
          }
        });
      },
      redirect: true,
      callback_url: `${process.env.REACT_APP_DOAMIN}/payment/paymentVerificationChangeBooking?cCode=${oldBookingId[0]}&dCode=${oldBookingId[1]}&year=${oldBookingId[2]}&sNo=${oldBookingId[3]}&cCodeNew=${newBookingId[0]}&dCodeNew=${newBookingId[1]}&yearNew=${newBookingId[2]}&sNoNew=${newBookingId[3]}`,
      prefill: {
        //We recommend using the prefill parameter to auto-fill customer's contact information, especially their phone number
        name: localStorage.getItem("username"), //your customer's name
        email: localStorage.getItem("email"),
        contact: localStorage.getItem("phoneNumber"), //Provide the customer's phone number for better conversion rates
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
    setLoader(false);
  }
  async function goTopayment() {
    setLoader(true);
    try {
      const response = await fetch(process.env.REACT_APP_DOAMIN + "/DateChangeBooking/changeBooking", {
        method: "POST",
        headers: {
          "Content-Type": "Application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(changeBookingData),
      });
      const result = await response.json();
      if (response.status == 200) {
        localStorage.setItem("bookingId", JSON.stringify(result.newBookingId));
        if (result.orderId) diplayRazorpay(result.orderId, result.oldBookingId, result.newBookingId);
        else if (result.returnUrl) window.open(result.returnUrl, "_self", "noopener,noreferrer");
        else navigate("/invoice", { state: changeBookingData.bookingId });
      } else throw result;
    } catch (err) {
      setMsg({
        status: "failed",
        message: err.message,
        alertMessage: "",
      });
    }
  }
  function proceedPayment(val) {
    if (book.isReturnDate) {
      // changeBookingData.voyageId = "";
      // changeBookingData.ferryId = "";
      // changeBookingData.voyageYear = 0;
      // changeBookingData.returnVoyageId = book.returnVoyageId;
      // changeBookingData.returnVoyageYear = book.voyageYear;
      // changeBookingData.returnFerryId = book.ferryId;
    }
    changeBookingData.paymentGateway = val;
    changeBookingData.total = fareDetails.total;
    changeBookingData.bookingId = JSON.parse(localStorage.getItem("changeBookingId"));
    goTopayment();
    // localStorage.setItem('bookingId', JSON.stringify(changeBookingData.bookingId));
    // async function goTopayment() {
    //     setLoader(true)
    //     try {
    //         const response = await fetch(process.env.REACT_APP_DOAMIN + '/DateChangeBooking/changeBooking', {
    //             method: "POST",
    //             headers: {
    //                 "Content-Type": "Application/json",
    //                 "Authorization": `Bearer ${localStorage.getItem('token')}`
    //             },
    //             body: JSON.stringify(changeBookingData)
    //         });

    //         const result = await response.json();
    //         if (response.status == 200) {
    //             // localStorage.setItem('bookingId', JSON.stringify(proceedPaymentDts.bookingId));
    //             if (result.orderId) {
    //                 localStorage.setItem('bookingId', JSON.stringify(result.newBookingId));
    //                 diplayRazorpay(result.orderId, result.oldBookingId, result.newBookingId)
    //             }
    //             else {
    //                 localStorage.setItem('bookingId', JSON.stringify(result.newBookingId));
    //                 navigate('/invoice', { state: changeBookingData.bookingId })
    //             }
    //         }
    //         else {
    //             throw result
    //         }
    //     } catch (err) {
    //         setMsg({
    //             status: 'failed',
    //             message: err.message,
    //             alertMessage: ""
    //         })
    //     }
    // }
    // if (isCredit) {
    //     if (proceedPaymentDts.paymentAmount > creditLimit) {
    //         alertMessage: "Topup the money and try again"
    //         setCreditMsg(true)

    //     } else goTopayment()
    // } else goTopayment()
  }
  function handleConfirm() {
    setLoader(true);
    if (book.isReturnDate) {
      // changeBookingData.voyageId = "";
      // changeBookingData.ferryId = "";
      // changeBookingData.voyageYear = 0;
      // changeBookingData.returnVoyageId = book.returnVoyageId;
      // changeBookingData.returnVoyageYear = book.voyageYear;
      // changeBookingData.returnFerryId = book.ferryId;
    }
    changeBookingData.bookingId = JSON.parse(localStorage.getItem("changeBookingId"));
    if (!localStorage.getItem("token")) {
      // localStorage.setItem('book', JSON.stringify(book));
      // localStorage.setItem('travelDate', state.travelDate.split('-').reverse().join('/'))
      window.location.assign("/");
    } else {
      changeBooking(changeBookingData).then((data) => {
        if (data) {
          setFareDetails({
            chargeAmount: data.chargeAmount + data.returnWayCharges,
            tax: data.taxesOnWay + data.taxesReturnWay,
            total: data.total,
          });
          setLoader(false);
          setCreditLimit(data.creditLimit);
          setIsCredit(data.isCredit);
          setMsg({
            status: "success",
            message: data.message,
            alertMessage: data.alertMessage,
          });
          setProceedPaymentDts({ ...proceedPaymentDts, bookingId: data.bookingId, paymentAmount: data.total });
          // localStorage.setItem('changeBookingId', JSON.stringify(data.bookingId));
          setConfirm(true);
        }
        setTimeout(() => {
          setMsg({ status: "success", message: "", alertMessage: "" });
        }, 3000);
      });
    }
  }
  function handleReserve() {
    setLoader(true);
    reserve(changeBookingData).then((data) => {
      if (data) {
        setLoader(false);
        setMsg({ status: "success", message: data.message, alertMessage: data.alertMessage });
        setTimeout(() => {
          setMsg({ msg: "success", message: "", alertMessage: "" });
          setLoader(false);
          navigate("/dashboard");
        }, 3000);
      } else {
        setLoader(false);
        setMsg({ status: "fail", message: "Reservation Failed", alertMessage: "Please Try again" });
        setTimeout(() => {
          setMsg({ status: "fail", message: "", alertMessage: "" });
          setLoader(false);
          navigate("/dashboard");
        }, 3000);
      }
    });
  }
  function handleCreditReserve() {
    setLoader(true);
    creditReserve({ bookingId: proceedPaymentDts.bookingId }).then((data) => {
      if (data) {
        setLoader(false);
        setMsg({ status: "success", message: data.message, alertMessage: data.alertMessage });
        setTimeout(() => {
          setMsg({ msg: "success", message: "", alertMessage: "" });
          setLoader(false);
          navigate("/dashboard");
        }, 3000);
      } else {
        setLoader(false);
        setMsg({ status: "fail", message: "Reservation Failed", alertMessage: "Please Try again" });
        setTimeout(() => {
          setMsg({ status: "fail", message: "", alertMessage: "" });
          setLoader(false);
          navigate("/dashboard");
        }, 3000);
      }
    });
  }
  function handleCancel() {
    setLoader(true);
    cancelReserving({ bookingId: proceedPaymentDts.bookingId }).then((data) => {
      setLoader(false);
      setMsg({ status: "success", message: data.message, alertMessage: "" });
      setTimeout(() => navigate("/dashboard"), 3000);
      localStorage.removeItem("bookingId");
    });
  }
  function SLAgentProceedPayment() {
    if (proceedPaymentDts.paymentAmount > creditLimit) {
      setCreditMsg(true);
    } else goTopayment(); //store all precedures in backend
  }
  function showPaymentOptions() {
    if (isCredit) SLAgentProceedPayment();
    else setPaymentOptions(true);
  }
  const style = {
    totalBatch: {
      width: "fit-content",
      backgroundColor: "#D9D9D9",
      borderRadius: "12px",
    },
  };
  return (
    <div className="p-1">
      {fareDetails.total > 0 && (
        <div className="p-1 sm-m-a" style={style.totalBatch}>
          <p className="d-f f-spb f-a-c">
            <span>New Booking Fare:&nbsp; </span>
            <span className="b-600" style={{ fontFamily: "Arial, sans-serif" }}>
              {fareDetails.chargeAmount.toLocaleString("en-GB", { style: "currency", currency: book.oneWayData.chargeCurrency })}
            </span>
          </p>
          <p className="d-f f-spb f-a-c">
            <span>Taxes: </span>
            <span className="b-600" style={{ fontFamily: "Arial, sans-serif" }}>
              {fareDetails.tax.toLocaleString("en-GB", { style: "currency", currency: book.oneWayData.chargeCurrency })}
            </span>
          </p>
          <p className="d-f f-spb f-a-c">
            <span>Total Fare: </span>
            <span className="b-600" style={{ fontFamily: "Arial, sans-serif" }}>
              {fareDetails.total.toLocaleString("en-GB", { style: "currency", currency: book.oneWayData.chargeCurrency })}
            </span>
          </p>
        </div>
      )}
      <p className="mtb-1-5 fs-20px b-500 sm-txt-c">Your voyages</p>
      <div className="d-f f-col f-a-c r-gap-1-5">
        {isMob && <MobYourVoyages book={book.oneWayData} maxLuggage={book.oneWayData.maxLuggage} />}
        {isTab && <TabYourVoyages book={book.oneWayData} maxLuggage={book.oneWayData.maxLuggage} />}
        {isDesk && <YourVoyages book={book.oneWayData} maxLuggage={book.oneWayData.maxLuggage} />}

        {book.round && isMob && <MobYourVoyages book={book.roundData} maxLuggage={book.roundData.maxLuggage} />}
        {book.round && isTab && <TabYourVoyages book={book.roundData} maxLuggage={book.roundData.maxLuggage} />}
        {book.round && isDesk && <YourVoyages book={book.roundData} maxLuggage={book.roundData.maxLuggage} />}
      </div>
      {/* {confirm && <div className='m-a mt-2' style={{ maxWidth: '1080px' }}><PassengerDts bookingId={JSON.parse(localStorage.getItem('bookingId'))} setPaxSuccess={setPaxSuccess} /></div>}
            {confirm && <div className="mt-2 r-gap-8 d-f f-wrap f-spb m-a" style={{ maxWidth: '1080px' }}>
                <LuggageSelection data={book} setProceedPaymentDts={setProceedPaymentDts} proceedPaymentDts={proceedPaymentDts} />
                <Roundcontext.Provider value={book.round}>
                    <MealSelection data={book} travelDate={travelDate} setProceedPaymentDts={setProceedPaymentDts} proceedPaymentDts={proceedPaymentDts} />
                </Roundcontext.Provider>
            </div>} */}
      <div className="mt-2 d-f f-j-e">
        {!confirm && (
          <div className="d-f sm-f-col sm-m-a c-gap-8 r-gap-1">
            <button
              onClick={() => {
                localStorage.removeItem("changeBookingId");
                navigate("/dashboard");
              }}
              className="pointer br-btn primary"
              style={{ width: "256px", height: "48px" }}
            >
              Cancel
            </button>
            <button onClick={handleConfirm} className="pointer btn" style={{ width: "256px", height: "48px" }}>
              Confirm
            </button>
          </div>
        )}
        {confirm && (
          <div className="d-f c-gap-8 sm-f-col sm-m-a sm-r-gap-1">
            <button
              onClick={() => {
                localStorage.removeItem("changeBookingId");
                navigate("/dashboard");
              }}
              className="pointer br-btn primary"
              style={{ width: "256px", height: "48px" }}
            >
              Cancel Changes
            </button>
            <button onClick={showPaymentOptions} className="pointer btn" style={{ width: "256px", height: "48px" }}>
              Proceed Changes
            </button>
          </div>
        )}
      </div>
      {msg.message && <Success data={msg} setMsg={setMsg} />}
      {creditMsg && <CreditMsg setCreditMsg={setCreditMsg} handleCreditReserve={handleCreditReserve} />}
      {paymentSuccess && <PaymentSuccess bookingId={proceedPaymentDts.bookingId} />}
      {loader && (
        <div className="overlay">
          <Loader />
        </div>
      )}
      {showPayment && (
        <div className="overlay">
          <Payment proceedPayment={!proceedPayment} />
        </div>
      )}
      {paymentOptions && <PaymentOptions proceedPayment={proceedPayment} setQrCodeModal={setQrCodeModal} setQrCode={setQrCode} setMsg={setMsg} setLoader={setLoader} proceedPaymentDts={proceedPaymentDts} setPaymentOptions={setPaymentOptions} />}
    </div>
  );
}

function Success({ data, setMsg }) {
  return (
    <div className="overlay">
      <div className="p-c p-1" style={{ maxWidth: "450px", height: "fit-content" }}>
        <div className="p-1 br-r-8 bg-white">
          <div className="txt-a-c">
            <img src={data.status == "success" ? successImg : failImg} alt="" width="80px" />
          </div>
          <div className="txt-a-c">
            <p className="primary">{data.message}</p>
            <p>{data.alertMessage}</p>
            {/* <button className='pointer msg-btn mt-1' onClick={() => setMsg({ msg: "", alertMessage: "" })}>ok</button> */}
          </div>
        </div>
      </div>
    </div>
  );
}
function CreditMsg({ setCreditMsg, handleCreditReserve }) {
  const navigate = useNavigate();
  return (
    <div className="overlay">
      <div className="p-c p-1" style={{ maxWidth: "450px", height: "fit-content" }}>
        <div className="p-1 br-r-8 bg-white">
          <div className="txt-a-c">
            <img src={warningImg} alt="" width="80px" />
          </div>
          <div className="txt-a-c">
            <p className="primary">Your credit is insufficient</p>
            <p>Do you want to reserve?</p>
            {/* <button className='pointer msg-btn mt-1' onClick={() => setMsg({ msg: "", alertMessage: "" })}>ok</button> */}
            <div className="d-f f-j-c c-gap-1 mt-2 pb-1">
              <button
                onClick={() => {
                  setCreditMsg(false);
                  navigate("/dashboard");
                }}
                className="btn pointer"
                style={{ width: "90px", height: "34px" }}
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  setCreditMsg(false);
                  handleCreditReserve();
                }}
                className="br-btn primary pointer"
                style={{ width: "90px", height: "34px" }}
              >
                Reserve
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
function PaymentSuccess({ bookingId }) {
  const navigate = useNavigate();
  return (
    <div className="overlay">
      <div className="sm-malr-1 p-c p-1 br-r-8 bg-white txt-a-c" style={{ width: "300px", height: "fit-content" }}>
        <p className="primary">Payment Successfull</p>
        <button className="pointer msg-btn mt-1" onClick={() => navigate("/seat-selection", { state: bookingId })}>
          ok
        </button>
      </div>
    </div>
  );
}
function PaymentOptions({ setPaymentOptions, proceedPayment }) {
  return (
    <div className="overlay">
      <div className="p-c p-1 scale" style={{ width: "fit-content", height: "fit-content" }}>
        <div className="bg-white br-r-8 ptb-1 plr-1-5">
          <div className="d-f f-j-e sm-f-j-c">
            <p onClick={() => setPaymentOptions(false)} className="d-f f-a-c f-j-c pointer" style={{ width: "30px", height: "30px", borderRadius: "50px", border: "solid 1px black" }}>
              &times;
            </p>
          </div>
          <h1 className="txt-a-c b-500 mt-1 primary fs-20px">Confirm and proceed to payment!</h1>
          <p className="txt-a-c gray mt-1 gray">
            You will be redirected to the payment gateway page. <br /> Please complete the payment within 15 minutes.
          </p>
          <div className="d-f mb-1 mt-2 c-gap-8 f-j-c">
            <button
              onClick={() => {
                if (true) setPaymentOptions(false);
                proceedPayment(2);
              }}
              className="btn pointer plr-1"
              style={{ width: "fit-content" }}
            >
              Confirm & Pay Now
            </button>
            {/* <div onClick={() => { if (true) setPaymentOptions(false); proceedPayment(2) }} className='d-f f-col f-j-c br-r-8 pointer paymentHover' style={{ opacity: "1", width: 'fit-content', padding: ".5rem", border: "1px solid #DF743570" }}>
                            <img src={ccAvenuePayImg} alt="" />
                        </div> */}
            {/* <div onClick={() => { if (true) { setPaymentOptions(false); proceedPayment(1) } }} className='d-f f-col f-j-c br-r-8 pointer ' style={{ opacity: ".3", width: 'fit-content', padding: ".5rem", border: "1px solid #DF743570" }}>
                            <img src={razorPayImg} alt="" />
                        </div>
                        <div onClick={() => { if (false) { setPaymentOptions(false); } }} className='d-f f-col f-j-c br-r-8 pointer ' style={{ opacity: ".3", width: 'fit-content', padding: ".5rem", border: "1px solid #DF743570" }}>
                            <img src={hsbcImg} alt="" />
                        </div> */}
          </div>
          {/* <a target='_blank' href="https://test.ccavenue.com/transaction/transaction.do?command=initiateTransaction&encRequest=44d1b861f54c64b7f2d24cea9540f1b15b46609f979be9ee4a424d9c602e01cf6958b9a27533cc0bcf18b357808e7ab9781844d77baec8d8b556d462c960439cbc870e95093a8d4a56828c718db59f42aa962b16780fba48ab34146dd9b597785e07f46499cfdb9c8238caafeed02f3610a06994f15641cef90696d73080256c5c4b1e9f7ca9ceece2cd53f40390a494606543bea76e969c4dcb09fafcbabc316c94fefee73426c875a6aef537eece9e3ba9f479ce295c981c6c326692bcf2e7b9d6c83e39a951f07fc1751488c17d89a49b4fc2133f2f7ba811aad40954f245f61a044361c0f202b5f54a35cd7a6ff49e00c1800d95ebe4d58907211d21e616dc98bd28603b5dc7f17680507be496cf9395d2ac733694deb764a9a96b2fe39c95d536b4a138b5d51dfcc9083e432e4713f5e8c9204572488315682079d90d8d&access_code=AVUM67LC95AH37MUHA">click</a> */}
          {/* <div>
                        <form method="post" name="redirect" action="https://test.ccavenue.com/transaction/transaction.do?command=initiateTransaction" >
                            <input type="hidden" id="encRequest" name="encRequest" />
                            <input type="hidden" name="access_code" id="access_code" />
                            <input type="submit" />
                        </form>
                    </div> */}
        </div>
      </div>
    </div>
  );
}
