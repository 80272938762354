export default function OrderTray({ totalMeals, chargeCurrency }) {
  const food = [];
  let totalPrice = 0;
  totalMeals.forEach((meal) => {
    if (meal.quantity > 0) {
      totalPrice += meal.price * meal.quantity;
      food.push(meal);
    }
  });
  return (
    <div className="p-1 p-c" style={{ height: "fit-content" }}>
      <div className="p-8 bg-white br-r-12 m-a" style={{ maxWidth: "722px", maxHeight: "432px", overflow: "auto" }}>
        <div className="d-f f-spb">
          <p>Meal</p>
          <p>Qty</p>
        </div>
        <hr />
        {food.map((item) => (
          <div key={item.menuItemId}>
            <div className="d-f f-spb" style={{ borderBottom: "1px solid black", padding: ".5rem 0" }}>
              <span>{item.menuItemName || item.name}</span>
              {/* <span>{(item.price * item.quantity).toLocaleString("en-GB", { style: "currency", currency: chargeCurrency || "LKR" })}</span> */}
              <span>{item.quantity}</span>
            </div>
            <hr />
          </div>
        ))}
        {/* <div className="d-f f-spb mt-1">
          <span>Total Price (Tax excluded)</span>
          <span>{totalPrice.toLocaleString("en-GB", { style: "currency", currency: chargeCurrency || "LKR" })}</span>
        </div> */}
        {/* <hr />
        <hr /> */}
      </div>
    </div>
  );
}
