import { useState, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { ProcessContext, ChangeDatecontext } from "./ProcessContext";
export function BookBtn({ luggageInfo, children, isRound, id, book, setBook, type, data, setShowReturn }) {
  const value = useContext(ProcessContext);
  const value2 = useContext(ChangeDatecontext);
  const navigate = useNavigate();
  const { state } = useLocation();
  const style = {
    width: "130px",
    height: "48px",
  };
  function handleBook(e) {
    book.type = type;
    if (type == "one") {
      book.luggageInfo = luggageInfo;
      book.oneWayData = { ...data, chargeCurrency: book.chargeCurrency };
      book.ferryId = data.ferryId;
      book.voyageYear = data.voyageYear;
      book.oneWay = true;
      book.voyageId = id;
      // book.totalChargeAmount = data.totalChargeAmount;
      navigate("fare-summary", { state: { fields: state.fields, book, travelDate: value ? value.travelDate : value2.travelDate, returnTravelDate: value ? value.returnTravelDate : value2.returnTravelDate } });
    } else {
      if (isRound) {
        book.luggageInfo = luggageInfo;
        book.roundData = data;
        book.round = true;
        book.returnVoyageId = id;
        book.returnFerryId = data.ferryId;
        book.returnVoyageYear = data.voyageYear;
        // book.totalChargeAmount = data.totalChargeAmount;
        if (!book.oneWay) setShowReturn(false);
      } else {
        book.oneWayData = { ...data, chargeCurrency: book.chargeCurrency };
        book.ferryId = data.ferryId;
        book.voyageYear = data.voyageYear;
        book.voyageId = id;
        book.oneWay = true;
        setShowReturn(true);
      }
      book.isReservation = false;
      if (book.oneWay && book.round) navigate("fare-summary", { state: { fields: state.fields, book, travelDate: value ? value.travelDate : value2.travelDate, returnTravelDate: value ? value.returnTravelDate : value2.returnTravelDate } });
      // setShowReturn(true);
      // setBook({ ...book, returnVoyageId: id })
    }
  }
  return (
    <>
      {type == "one" ? (
        <button className="btn primary pointer" style={style} onClick={handleBook}>
          {id == book.voyageId ? "Booked" : "Book"}
        </button>
      ) : (
        <button className="btn primary pointer" style={style} onClick={handleBook}>
          {id == book.returnVoyageId ? "Booked" : "Book"}
        </button>
      )}
    </>
  );
}

export function BackBtn() {
  const navigate = useNavigate();
  return (
    <div
      id="backBtn"
      onClick={() => navigate(-1)}
      className="d-f c-cont"
      style={{
        width: "fit-content",
        height: "24px",
        width: "24px",
        borderRadius: "50%",
      }}
    >
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 22C17.523 22 22 17.523 22 12C22 6.477 17.523 2 12 2C6.477 2 2 6.477 2 12C2 17.523 6.477 22 12 22Z" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M13.26 15.53L9.73999 12L13.26 8.47" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </div>
  );
}
