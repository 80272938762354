import mealMob from "../../assets/images/mealSelectionMobile.png";
import mealImg from "../../assets/images/mealthusha.jpg";
import successImg from "../../assets/images/success.png";
import { useEffect, useRef, useState } from "react";
// import { bookFood, getFood } from "../../api/api";
import Loader from "../icons/Loader";
import PaxList from "./PaxList";
import Meals from "./Meals";
import { fetchApi } from "../../api/utils";
import { useMediaQuery } from "react-responsive";
import OrderTray from "./OrderTray";
import RemovePaxMeal from "./RemovePaxMeal";

export default function MealSelection({ completedPaxes, setCompletedPaxes, totalMeal, setTotalMeal, data, travelDate, proceedPaymentDts, setProceedPaymentDts }) {
  const isMob = useMediaQuery({ query: "(max-width:1024px)" });
  const isDesk = useMediaQuery({ query: "(min-width:1025px)" });
  const bookingId = proceedPaymentDts.bookingId;
  const [show, setShow] = useState(false);
  const [meals, setMeals] = useState([]);
  const [menuList, setMenuList] = useState([]);
  const [menuListReturn, setMenuListReturn] = useState([]);
  const [subMenu, setSubMenu] = useState([]);
  const [menuType, setMenuType] = useState([]);
  const [maxMealsCount, setMaxMealsCount] = useState(0);
  const [paxList, setPaxList] = useState([]);
  const [showPaxList, setShowPaxList] = useState(false);
  const [mealInfo, setMealInfo] = useState({
    oneWayAmount: 0,
    roundWayAmount: 0,
  });
  const [currentMealInfo, setCurrentMealInfo] = useState({
    oneWayAmount: 0,
    roundWayAmount: 0,
  });
  const [selectedMenu, setSelectedMenu] = useState();
  const [selectedSubMenu, setSelectedSubMenu] = useState(0);
  const selectedMeal = useRef();
  const [currentPax, setCurrentPax] = useState({});
  const chargeCurrency = data.chargeCurrency;
  const [totalMeals, setTotalMeals] = useState([]);
  const [mealTotalPrice, setMealTotalPrice] = useState(0);
  const [isRemoved, setIsRemoved] = useState(false);
  const [isMealsSelectionForRound, setIsMealSelectionForRound] = useState(true);
  const [orderDetails, setOrderDetails] = useState([]);

  function orderTray(type) {
    let food = [];
    paxList.forEach((pax) => {
      if (pax[type]) food.push(...pax[type]);
    });
    const result = Object.groupBy(food, ({ code }) => code);
    food = [];
    for (const meal in result) {
      console.log(result[meal]);
      result[meal].forEach((item) => {
        item.quantity += 1;
      });
      food.push(result[meal][0]);
      console.log(result[meal][0]);
    }
    // result.forEach((meal) => {
    //   console.log(meal);
    //   if (meal.length > 1) {
    //     meal.forEach((item) => {});
    //   }
    // });
    setOrderDetails(food);
  }
  function filterMeals(pax, isRound, id = selectedMenu, subId = selectedSubMenu) {
    const lists = isRound ? menuListReturn : menuList;
    // lists.forEach((item) => (item.quantity = 0));
    console.log(isRound);
    const filteredMeals = lists.filter((item) => {
      if (id == item.majorGroupCode && subId == item.subGroupCode) {
        if (pax[isRound ? "returnFood" : "food"]) {
          pax[isRound ? "returnFood" : "food"].forEach((meal) => {
            if (item.menuItemId === meal.code) {
              item.quantity = meal.quantity;
              console.log(item.menuItemName);
            } else {
              console.log(item.menuItemName);
              // item.quantity = 0;
            }
          });
        } else {
          // item.quantity = 0;
          console.log(item);
        }
        return item;
      }
    });
    setSelectedSubMenu(subId);
    setMeals(filteredMeals);
  }
  useEffect(() => {
    fetchApi("/Menu/getMenu", {
      body: {
        companyCode: bookingId[0],
        documentCode: bookingId[1],
        bookingYear: bookingId[2],
        bookingSerial: bookingId[3],
      },
    }).then((data) => {
      console.log(data);
      setMaxMealsCount(data.maxMenuCount);
      setMenuType(data.menuCategory);
      setSubMenu(data.subMenu);
      setMenuList(
        data.menuList.map((item) => {
          return { ...item, quantity: 0 };
        })
      );
      setMenuListReturn(
        data.menuListReturn.map((item) => {
          return { ...item, quantity: 0 };
        })
      );
      setSelectedMenu(data.menuCategory[0].majorGroupCode);
      setSelectedSubMenu(data.subMenu[0].subGroupCode);
      // filterMeals(data.menuCategory[0].majorGroupCode, data.subMenu[0].subGroupCode, data.menuList);
    });
    fetchApi("/Menu/get-food", {
      body: {
        companyCode: bookingId[0],
        documentCode: bookingId[1],
        bookingYear: bookingId[2],
        bookingSerial: bookingId[3],
      },
    }).then((data) => {
      if (data) {
        console.log(data);
        setPaxList(data.paxFoodList);
        setTotalMeal(data.totalMeal);
        // setLoader(false);
      }
    });
  }, []);
  const style = {
    wrapper: {
      maxWidth: "512px",
      height: "fit-content",
      boxShadow: " 0px 0px 8px 0px rgba(0, 0, 0, 0.25)",
      borderRadius: "1rem",
      overflow: "hidden",
    },
    btn: {
      width: "196px",
      height: "48px",
      textDecoration: "none",
      border: "1px solid var(--primaryColor)",
    },
  };
  return (
    <div>
      <h1 className="fs-20px b-500">Select Preferred Meal (Optional)</h1>
      <div className="mt-2 d-f f-spb f-col" style={style.wrapper}>
        <img src={isDesk ? mealImg : mealMob} alt="" width="100%" height="192px" />
        <div className="d-f f-col p1-5 r-gap-1-5">
          <p>Indulge in a tailored culinary experience. Select your preferred meal and savor every moment.</p>
          {/* {!(mealInfo.oneWayAmount + mealInfo.roundWayAmount) && (
            <button onClick={() => setShow(true)} className="sm-m-a pointer br-btn b-500 d-f f-a-c f-j-c primary br-r-8" style={style.btn}>
              Select Meal
            </button>
          )} */}
          {totalMeal.amount + totalMeal.returnAmount === 0 && (
            <button onClick={() => setShowPaxList(true)} className="sm-m-a pointer br-btn b-500 d-f f-a-c f-j-c primary br-r-8" style={style.btn}>
              Select Meal
            </button>
          )}
        </div>
        {totalMeal.amount + totalMeal.returnAmount > 0 && <Detail filterMeals={filterMeals} setPaxList={setPaxList} paxList={paxList} setOrderDetails={setOrderDetails} orderDetails={orderDetails} orderTray={orderTray} setShowPaxList={setShowPaxList} totalMeal={totalMeal} setTotalMeal={setTotalMeal} setIsRemoved={setIsRemoved} mealTotalPrice={mealTotalPrice} setMealTotalPrice={setMealTotalPrice} chargeCurrency={chargeCurrency} setProceedPaymentDts={setProceedPaymentDts} proceedPaymentDts={proceedPaymentDts} setTotalMeals={setTotalMeals} totalMeals={totalMeals} data={data} mealInfo={mealInfo} setMealInfo={setMealInfo} setShow={setShow} />}
      </div>
      <div className="overlay" style={{ display: show ? "block" : "none", top: isMob ? "30px" : 0 }}>
        <Meals
          isMealsSelectionForRound={isMealsSelectionForRound}
          setSelectedMenu={setSelectedMenu}
          setSelectedSubMenu={setSelectedSubMenu}
          selectedMenu={selectedMenu}
          selectedSubMenu={selectedSubMenu}
          selectedMeal={selectedMeal}
          meals={meals}
          setMeals={setMeals}
          filterMeals={filterMeals}
          totalMeal={totalMeal}
          maxMealsCount={maxMealsCount}
          menuType={menuType}
          subMenu={subMenu}
          menuList={menuList}
          menuListReturn={menuListReturn}
          currentPax={currentPax}
          mealTotalPrice={mealTotalPrice}
          setMealTotalPrice={setMealTotalPrice}
          currentMealInfo={currentMealInfo}
          setCurrentMealInfo={setCurrentMealInfo}
          travelDate={travelDate}
          chargeCurrency={chargeCurrency}
          isRound={data.round}
          setTotalMeal={setTotalMeal}
          setProceedPaymentDts={setProceedPaymentDts}
          proceedPaymentDts={proceedPaymentDts}
          setShow={setShow}
          setMealInfo={setMealInfo}
          mealInfo={mealInfo}
          setPaxList={setPaxList}
        />
      </div>
      <div className="overlay" style={{ display: showPaxList ? "block" : "none", top: isMob ? "30px" : 0 }}>
        <PaxList setIsMealSelectionForRound={setIsMealSelectionForRound} paxList={paxList} filterMeals={filterMeals} setShow={setShow} setCurrentPax={setCurrentPax} totalMeal={totalMeal} setTotalMeal={setTotalMeal} data={data} proceedPaymentDts={proceedPaymentDts} setShowPaxList={setShowPaxList} />
      </div>
      {isRemoved && <Success setIsRemoved={setIsRemoved} />}
    </div>
  );
}

function Detail({ filterMeals, setPaxList, paxList, setOrderDetails, orderDetails, orderTray, setShowPaxList, totalMeal, setTotalMeal, setIsRemoved, chargeCurrency, proceedPaymentDts, totalMeals, data, mealInfo, setShow }) {
  const [removeLoader, setRemoveLoader] = useState(false);
  const [removeType, setRemoveType] = useState("");
  const bookingId = proceedPaymentDts.bookingId;
  const [showRemovPax, setShowRemovPax] = useState("");
  // function handleRemove(type) {
  //   setRemoveType(type);
  //   setRemoveLoader(true);
  //   let foodAmount = 0;
  //   let returnFoodAmount = 0;
  //   setTimeout(() => {
  //     if (type == "oneWay") {
  //       totalMeals.map((item) => {
  //         if (item.oneWayQty > 0) {
  //           foodAmount += item.oneWayQty * item.price;
  //         }
  //       });
  //     } else {
  //       totalMeals.map((item) => {
  //         if (item.returnQty > 0) {
  //           returnFoodAmount += item.returnQty * item.price;
  //         }
  //       });
  //     }
  //     const foodType = type == "oneWay" ? "food" : "returnFood";
  //     setProceedPaymentDts({
  //       ...proceedPaymentDts,
  //       paymentAmount: proceedPaymentDts.paymentAmount - (foodAmount + returnFoodAmount),
  //       [foodType]: [],
  //     });
  //     setMealInfo({ ...mealInfo, [type + "Amount"]: 0 });
  //     const mealType = type == "oneWay" ? "oneWayQty" : "returnQty";
  //     const newTotalMeals = totalMeals.map((item) => {
  //       item[mealType] = 0;
  //       return item;
  //     });
  //     setMealTotalPrice(mealTotalPrice - (foodAmount + returnFoodAmount));
  //     setTotalMeals(newTotalMeals);
  //     setRemoveLoader(false);
  //     setIsRemoved(true);
  //   }, 2000);
  // }
  function handleRemove(type) {
    setRemoveType(type);
    setRemoveLoader(true);
    setTimeout(() => {
      // getFood({
      //   companyCode: bookingId[0],
      //   documentCode: bookingId[1],
      //   bookingYear: bookingId[2],
      //   bookingSerial: bookingId[3],
      // }).then((data) => {
      //   if (data) {
      //     bookFood({
      //       food: type == "oneWay" ? [] : data.bookedFood.food,
      //       returnFood: type == "roundWay" ? [] : data.bookedFood.returnFood,
      //       paxNumber: {
      //         companyCode: bookingId[0],
      //         documentCode: bookingId[1],
      //         bookingYear: bookingId[2],
      //         bookingSerial: bookingId[3],
      //         seqNo: 1,
      //       },
      //       requestType: "string",
      //     }).then((data) => {
      //       if (data) {
      //         setTotalMeal({ amount: data.totalMeal.amount, returnAmount: data.totalMeal.returnAmount });
      //         setRemoveLoader(false);
      //         setIsRemoved(true);
      //       }
      //     });
      //   }
      // });
    }, 2000);
  }
  const [showOrderTray, setShowOrderTray] = useState("");
  return (
    <div className="p-1">
      {totalMeal.amount > 0 && (
        <>
          {" "}
          <hr style={{ opacity: ".2" }} />
          <div className="d-f f-spb ptb-1 f-end">
            <div className="d-f f-col">
              <p className="primary">
                {data.oneWayData.voyageFrom} to {data.oneWayData.voyageTo}
              </p>
              {totalMeal.amount > 0 && (
                <p onClick={() => orderTray("food")} className="primary b-700 pointer" style={{ textDecoration: "underline" }}>
                  Order Tray
                </p>
              )}
              <p>
                Total:{" "}
                <span className="b-600" style={{ fontFamily: "Arial, sans-serif" }}>
                  {totalMeal.amount.toLocaleString("en-GB", { style: "currency", currency: data.chargeCurrency })}
                </span>
              </p>
            </div>
            <div className="p-r txt-dec-u pointer" onClick={() => setShowRemovPax("food")} style={{ minWidth: "35px", minHeight: "35px" }}>
              {removeType == "oneWay" && removeLoader ? <Loader color="#1F3167" /> : "Remove"}
            </div>
          </div>
        </>
      )}
      {totalMeal.returnAmount > 0 && (
        <>
          <hr style={{ opacity: ".2" }} />
          <div className="d-f f-spb ptb-1 f-end">
            <div className="d-f f-col">
              <p className="primary">
                {data.roundData.voyageFrom} to {data.roundData.voyageTo}
              </p>
              {totalMeal.returnAmount > 0 && (
                <p onClick={() => orderTray("returnFood")} className="primary b-700 pointer" style={{ textDecoration: "underline" }}>
                  Order Tray
                </p>
              )}
              <p>
                Total:{" "}
                <span className="b-600" style={{ fontFamily: "Arial, sans-serif" }}>
                  {totalMeal.returnAmount.toLocaleString("en-GB", { style: "currency", currency: data.chargeCurrency })}
                </span>
              </p>
            </div>
            <div className="p-r txt-dec-u pointer" onClick={() => setShowRemovPax("returnFood")} style={{ minWidth: "35px", minHeight: "35px" }}>
              {removeType == "roundWay" && removeLoader ? <Loader color="#1F3167" /> : "Remove"}
            </div>
          </div>
        </>
      )}
      <div className="d-f f-spb f-end">
        <button onClick={() => setShowPaxList(true)} className="pointer btn">
          Change
        </button>
        <p className="txt-end">
          Total Fare: <span className="b-600">{(totalMeal.amount + totalMeal.returnAmount).toLocaleString("en-GB", { style: "currency", currency: data.oneWayData.chargeCurrency })}</span>
        </p>
      </div>
      {orderDetails.length > 0 && (
        <div onClick={() => setOrderDetails([])} className="overlay">
          <OrderTray chargeCurrency={chargeCurrency} totalMeals={orderDetails} showOrderTray={showOrderTray} />
        </div>
      )}
      {showRemovPax && (
        <div className="overlay">
          <RemovePaxMeal filterMeals={filterMeals} setShowRemovPax={setShowRemovPax} setPaxList={setPaxList} setShowPaxList={setShowPaxList} paxList={paxList} type={showRemovPax} totalMeal={totalMeal} setTotalMeal={setTotalMeal} chargeCurrency={chargeCurrency} />
        </div>
      )}
    </div>
  );
}

// function OrderTray({ chargeCurrency, totalMeals, proceedPaymentDts, showOrderTray, mealInfo }) {
//   const food = [];
//   const type = showOrderTray == "one" ? "food" : "returnFood";
//   const travelType = showOrderTray == "one" ? "oneWayQty" : "returnQty";
//   const tax = showOrderTray == "one" ? mealInfo.oneWayAmount : mealInfo.roundWayAmount;
//   let totalPrice = 0;
//   totalMeals.forEach((meal) => {
//     if (meal[travelType] > 0) {
//       totalPrice += meal.price * meal[travelType];
//       food.push(meal);
//     }
//   });
//   return (
//     <div className="p-1 p-c" style={{ height: "fit-content" }}>
//       <div className="p-8 bg-white br-r-12 m-a" style={{ maxWidth: "722px", maxHeight: "432px", overflow: "auto" }}>
//         <div className="d-f f-spb">
//           <p>Meal</p>
//           <p>Price</p>
//         </div>
//         <hr />
//         {food.map((item) => (
//           <div key={item.menuItemId + showOrderTray}>
//             <div className="d-f f-spb" style={{ borderBottom: "1px solid black", padding: ".5rem 0" }}>
//               <span>
//                 {item.menuItemName} x {item[travelType]}
//               </span>
//               <span>{(item.price * item[travelType]).toLocaleString("en-GB", { style: "currency", currency: item.chargecurrency })}</span>
//             </div>
//             <hr />
//           </div>
//         ))}
//         <div className="d-f f-spb mt-1">
//           <span>Total Price</span>
//           <span>{totalPrice.toLocaleString("en-GB", { style: "currency", currency: chargeCurrency })}</span>
//         </div>
//         <hr />
//         <hr />
//       </div>
//     </div>
//   );
// }

function Success({ setIsRemoved }) {
  return (
    <div className="overlay">
      <div className="p-c p-1" style={{ width: "fit-content", height: "fit-content" }}>
        <div className="p-1 br-r-8 bg-white">
          <div className="txt-a-c">
            <img src={successImg} alt="" width="80px" />
          </div>
          <div className="txt-a-c">
            <p className="primary">You have successfully cancelled Pre-Order Meals!</p>
            <p>Please continue your booking!</p>
            <button className="pointer btn mt-1" onClick={() => setIsRemoved(false)} style={{ width: "120px" }}>
              Continue
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
// function Msg({ setMsg, maxMealsCount }) {
//   return (
//     <div className="overlay">
//       <div className="p-c p-1" style={{ width: "fit-content", height: "fit-content" }}>
//         <div className="p-1 br-r-8 bg-white">
//           <div className="txt-a-c">
//             <img src={warningImg} alt="" width="80px" />
//           </div>
//           <div className="txt-a-c">
//             <p className="primary">Maximum Meal Types Reached!</p>
//             <p className="gray">Your ticket has reached the allocated maximum meals selections:({maxMealsCount}) for passengers.</p>
//             <button className="pointer btn mt-1" onClick={() => setMsg(false)} style={{ width: "120px" }}>
//               Continue
//             </button>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }
