import { useState } from "react";
import { BookBtn } from "../btn";
import DateTimeCard from "../common/DateTimeCard";
import ViewDetails from "../common/ViewDetails";
export default function VoyageCard({ luggageInfo, data, book, isRound, setBook, type, setShowReturn }) {
  const [view, setView] = useState(false);
  const style = {
    container: {
      width: "100%",
      borderRadius: "16px",
      boxShadow: "0px 0px 8px 0px rgba(0, 0, 0, 0.25)",
    },
    codeBatch: {
      width: "95px",
      height: "24px",
      backgroundColor: "var(--primaryColor)",
      borderRadius: "0 0 4px 4px",
      color: "white",
      fontWeight: 500,
      textAlign: "center",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    amountBatch: {
      height: "62px",
      backgroundColor: "#D9D9D9",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexShrink: 0,
    },
    seatBatch: {
      width: "fit-content",
      backgroundColor: "#ec9e39",
      borderRadius: "0 0 4px 4px",
      padding: "0 .5rem",
    },
  };
  return (
    <div style={style.container} className="fade">
      <div className="d-f f-col">
        <div className="plr-1 d-f f-spb">
          <div className="fs-12px" style={style.codeBatch}>
            {data.voyageId}
          </div>
          {data.availableSeat <= 10 && (
            <span className="fs-12px b-500" style={style.seatBatch}>
              {data.availableSeat + " Seats Left"}
            </span>
          )}
        </div>
        <div style={{ margin: "1rem 1rem" }}>
          <DateTimeCard fs={{ date: "fs-12px", time: "fs-20px" }} data={data} />
        </div>
        <div className="txt-a-c m-b-1">
          <span className="fs-14px">Class:</span>
          <span className="fs-14px b-700">&nbsp;{data.className}</span>
        </div>
        <div style={style.amountBatch}>
          <span style={{ marginRight: ".8rem" }}>{book.chargeCurrency}</span>
          <span className="fs-1-5 b-600">{data.totalChargeAmount.toLocaleString()}</span>
        </div>
        <div className="d-f f-spb f-a-c m-1">
          <span className="fs-14px" style={{ color: "var(--primaryColor)", textDecoration: "underline", cursor: "pointer" }} onClick={() => setView(!view)}>
            {view ? "Close details" : "View details"}
          </span>
          <BookBtn luggageInfo={luggageInfo} isRound={isRound} book={book} setBook={setBook} type={type} id={data.voyageId} data={data} setShowReturn={setShowReturn}>
            Book
          </BookBtn>
        </div>
      </div>
      {view && <ViewDetails maxLuggage={data.maxLuggage} />}
    </div>
  );
}
