import { Link } from 'react-router-dom';
import logo from '../assets/images/SubhamLogo.png';
import frofileLogo from '../assets/images/profile.png';
import MenuIcon from '../components/icons/MenuIcon';
import Close from '../components/icons/Close';
import { useState } from 'react';
import Profile from '../components/common/Profile';

import { NavLink } from "react-router-dom";

const username = localStorage.getItem("token");
const token = localStorage.getItem("username");

export default function Header({ username, isLogin }) {
  const style = {
    header: {
      backgroundColor: "white",
      boxSizing: "border-box",
      padding: "1rem 1.5rem",
      width: "100%",
      height: "64px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      boxShadow: "0px 3px 6px 0px rgba(0, 0, 0, 0.10)",
      position: "fixed",
      top: 0,
      left: 0,
      zIndex: 1,
    },
    btn: {
      width: "171px",
      height: "48px",
      border: "none",
      color: "white",
      textDecoration: "none",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    linkWrapper: {
      display: "flex",
      columnGap: "3rem",
      alignItems: "center",
    },
  };
  return (
    <div id="header" style={style.header}>
      <div className="d-f f-spb f-a-c w-full m-a" style={{ maxWidth: "var(--maxWidth)" }}>
        <div>
          <Link to="https://sailsubham.com/">
            <img src={logo} alt="" width="130px" />
          </Link>
        </div>
        <div id="desk-link-wrapper" className="b-500" style={style.linkWrapper}>
          <LinkWrapper />
          {
            isLogin && <Profile name={username} />
            //  <Link to="/" className='pointer br-r-8 primary-bg login-btn' style={style.btn}>Login</Link>
          }
        </div>
        {/* <div id='tap-link-wrapper' className='b-500' style={{ ...style.linkWrapper, display: 'none' }}>
                <LinkWrapper />
                {isLogin ? <>
                    <Link to='dashboard' className='nav-link b-500'>Dashboard</Link>
                    <button onClick={logout} className='pointer br-r-8 login-btn' style={{ ...style.btn, color: 'black', border: '1px solid var(--primaryColor)', background: 'white' }}>Logout</button>
                    <img src={frofileLogo} alt="" />
                    <span>{username}</span>
                </>
                    : <Link to="login" className='pointer br-r-8 primary-bg login-btn' style={style.btn}>Login</Link>}

            </div> */}

        {/* <div id='menu-icon' style={{ display: 'none' }} onClick={() => setIsClicked(!isClicked)}>
                    <MenuIcon />
                </div> */}
        {/* {isClicked && < MobileNav onClick={setIsClicked} />} */}
      </div>
    </div>
  );
}
function LinkWrapper() {
  const [currentId, setCurrrentId] = useState(0);
  const data = [
    {
      id: 7,
      name: "Home",
      link: "https://sailsubham.com/",
    },
    {
      id: 1,
      name: "Book Now",
      link: "/home",
    },
    // {
    //   id: 2,
    //   name: "About Us",
    //   link: "/about-us",
    // },
    {
      id: 3,
      name: "Privacy Policy",
      link: "/privacy-policy",
    },
    {
      id: 4,
      name: "Travel Policies",
      link: "/travel-policy",
    },
    {
      id: 5,
      name: "Contact Us",
      link: "/contact-us",
    },
    {
      id: 6,
      name: "Login",
      link: "/",
    },
    ,
  ];
  return (
    <>
      {data.map((item) => {
        if (item.id == 6) {
          return !localStorage.getItem("token") ? (
            <Link key={item.id} onClick={() => setCurrrentId(item.id)} to={item.link} className="nav-link b-500 btn white  d-f f-j-c f-a-c">
              {item.name}
            </Link>
          ) : (
            ""
          );
        }
        // else {
        //     return (<Link key={item.id} onClick={() => { setCurrrentId(item.id); window.scrollTo(0, 0); }} to={item.link} style={{ color: item.id == currentId ? 'var(--primaryColor)' : 'black', fontWeight: item.id == currentId ? '700' : '400' }} className='nav-link'>{item.name}</Link>)

        // }
        else {
          return (
            <NavLink to={item.link} key={item.id} className="nav-link" onClick={() => window.scrollTo(0, 0)}>
              {item.name}
            </NavLink>
          );
        }
      })}
    </>
  );
}

function MobileNav({ onClick }) {
  const style = {
    container: {
      background: "white",
      width: "100vw",
      height: "100vh",
      position: "absolute",
      top: 0,
      left: 0,
    },
    btn: {
      width: "171px",
      height: "48px",
      border: "none",
      color: "white",
      textDecoration: "none",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  };
  return (
    <div style={style.container}>
      <div className="d-f f-col h-100">
        <div style={{ textAlign: "right", margin: "2rem 2rem 0 0" }}>
          <span onClick={() => onClick((click) => !click)} style={{ display: "inline-block" }}>
            <Close />
          </span>
        </div>
        <div className="d-f f-col f-spb a-i-c" style={{ flexGrow: 1 }}>
          <div className="d-f f-col r-gap-8 txt-a-c" style={{ marginTop: "77px" }}>
            <Link onClick={() => onClick((click) => !click)} to="plan&book" className="nav-link b-500">
              Plan & Book
            </Link>
            <Link onClick={() => onClick((click) => !click)} to="travel-information" className="nav-link b-500">
              Travel Information
            </Link>
            <Link onClick={() => onClick((click) => !click)} to="support" className="nav-link b-500">
              Support
            </Link>
          </div>
          <div style={{ marginBottom: "3rem" }}>
            <Link to="login" className="pointer br-r-8 primary-bg login-btn" style={style.btn}>
              Login
            </Link>
            <span
              className="txt-dec-u primary"
              onClick={() => {
                localStorage.clear();
                sessionStorage.clear();
                window.location.assign("/");
              }}
            >
              Logout
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
