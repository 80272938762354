import { useEffect, useState } from "react";
import Loader from "../icons/Loader";
import { getExtraLuggage } from "../../api/api";
import { fetchApi } from "../../api/utils";
export default function PaxList({ setIsMealSelectionForRound, paxList, filterMeals, setShow, setCurrentPax, totalMeal, setTotalMeal, proceedPaymentDts, data, setShowPaxList }) {
  const [submit, setSubmit] = useState(false);
  const [loader, setLoader] = useState(false);
  const isRound = data.round;
  const bookingId = proceedPaymentDts.bookingId;
  // const [paxList, setPaxList] = useState([]);
  const dataList = [
    {
      amount: 1000,
      amountReturn: 2000,
      name: "",
      paxNumber: 1,
      meal: [{ menuItemId: 1, qty: 2 }],
      returnMeal: [{ menuItemId: 1, qty: 2 }],
    },
    {
      amount: 1000,
      amountReturn: 2000,
      name: "",
      paxNumber: 2,
      meal: [{ menuItemId: 1, qty: 2 }],
      returnMeal: [{ menuItemId: 1, qty: 2 }],
    },
    {
      amount: 1000,
      amountReturn: 2000,
      name: "",
      paxNumber: 3,
      meal: [{ menuItemId: 1, qty: 3 }],
      returnMeal: [{ menuItemId: 1, qty: 2 }],
    },
  ];
  useEffect(() => {
    setLoader(true);
    // fetchApi("/Menu/get-food", {
    //   body: {
    //     companyCode: bookingId[0],
    //     documentCode: bookingId[1],
    //     bookingYear: bookingId[2],
    //     bookingSerial: bookingId[3],
    //   },
    // }).then((data) => {
    //   if (data) {
    //     console.log(data);
    //     setPaxList(data.paxFoodList);
    //     setTotalMeal(data.totalMeal);
    //     setLoader(false);
    //   }
    // });
  }, []);
  return (
    <div className="p-c p-1 br-r-1" style={{ maxWidth: "900px", maxHeight: "90%", height: "fit-content", overflow: "scroll" }}>
      <div className="bg-white p-4 br-r-1" style={{ height: "fit-content" }}>
        <h1 className="fs-20px  primary">Preferred Meals</h1>
        <p className="gray mtb-1 b-500">Choose your preferred meal for a more enjoyable and personalized travel experience.</p>
        <div className="flex justify-end items-center">
          <p className="fs-1-5 mr-4">Total :</p>
          <div className="p-r br-r-12 p-1" style={{ background: "var(--inputGray)", width: "fit-content", minWidth: "121px", height: "68px" }}>
            <span className="b-500 fs-1-5">{submit ? <Loader color="#1F3167" /> : (totalMeal.amount + totalMeal.returnAmount).toLocaleString("en-GB", { style: "currency", currency: data.oneWayData.chargeCurrency })}</span>
          </div>
        </div>
        <div className="flex justify-end pr-2 mt-4 mb-2">
          <p className="text-center font-medium" style={{ width: "144px" }}>
            {data.oneWayData.voyageFrom} - {data.oneWayData.voyageTo}
          </p>
          {isRound && (
            <p className="ml-4 text-center font-medium" style={{ width: "144px" }}>
              {data.oneWayData.voyageTo} - {data.oneWayData.voyageFrom}
            </p>
          )}
        </div>
        <div className="mb-2 flex flex-col">
          {paxList.map((pax, index) => (
            <LuggagePerPax setIsMealSelectionForRound={setIsMealSelectionForRound} filterMeals={filterMeals} setShowPaxList={setShowPaxList} setShow={setShow} setCurrentPax={setCurrentPax} from={data.oneWayData.voyageFrom} to={data.oneWayData.voyageTo} key={index} isRound={data.round} pax={pax} />
          ))}
          {/* {loader && <Loader color="#1F3167" />} */}
        </div>

        <div className="flex flex-end gap-x-4">
          <button
            onClick={() => {
              setShowPaxList(false);
            }}
            className="br-btn pointer"
            style={{ width: "171px" }}
          >
            Cancel
          </button>
          {/* <button onClick={handleSubmit} className="p-r btn pointer" style={{ opacity: `${validateErr.oneWay || validateErr.return || submit ? 0.4 : 1}` }} disabled={validateErr.oneWay || validateErr.return}>
            Confirm
          </button> */}
          <button className="btn pointer">Confirm</button>
        </div>
      </div>
    </div>
  );
}

function LuggagePerPax({ setIsMealSelectionForRound, filterMeals, setShowPaxList, setShow, pax, handleChange, isRound, setCurrentPax }) {
  const [validateErr, setValidateErr] = useState({ weight: "", returnWeight: "" });
  return (
    <>
      <p className="font-medium md-hidden overflow-hidden mb-2"> {pax.name || pax.paxNumber.seqNo}</p>
      <div className={`flex br-r-8 p-2 pl-4 justify-end md-justify-between items-center ${validateErr.weight || validateErr.returnWeight ? "mb-0" : "mb-4"}`} style={{ background: "var(--inputGray)" }}>
        <p className="font-medium hidden md-block overflow-hidden whitespace-nowrap mr-4"> {pax.name || pax.paxNumber.seqNo}</p>
        <div className="flex">
          <button
            onClick={() => {
              console.log(pax);
              setIsMealSelectionForRound(false);
              setCurrentPax(pax);
              setShowPaxList(false);
              setShow(true);
              filterMeals(pax, false);
            }}
            className={`${pax.food ? "border text-primary border-primary" : "bg-primary"} br-n white pointer mealAddBtn`}
            style={{
              width: "144px",
              height: "40px",
              borderRadius: "24px",
            }}
          >
            {pax.food ? "Change" : "Add"}
          </button>
          {isRound && (
            <button
              className={`${pax.returnFood ? "border text-primary border-primary" : "bg-primary"} ml-4 br-n white pointer mealAddBtn`}
              style={{
                width: "144px",
                height: "40px",
                borderRadius: "24px",
              }}
              onClick={() => {
                setIsMealSelectionForRound(true);
                setCurrentPax(pax);
                setShowPaxList(false);
                setShow(true);
                filterMeals(pax, true);
              }}
            >
              {pax.returnFood ? "Change" : "Add"}
            </button>
          )}
        </div>
      </div>
      <p className="red text-end text-xs pr-4">{validateErr.weight || validateErr.returnWeight}</p>
    </>
  );
}
