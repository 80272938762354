import food from "../../assets/images/meal.png";
import { Roundcontext } from "../ProcessContext";
import { useContext, useState } from "react";

export default function Meal({ chargeCurrency, totalMeals, maxMealsCount, data, meals, setMeals }) {
  const isRound = useContext(Roundcontext);
  const [add, setAdd] = useState(false);
  const style = {
    btn: {
      width: "144px",
      height: "40px",
      borderRadius: "24px",
    },
    roundBtn: {
      border: "none",
      borderRadius: "50%",
      height: "30px",
      width: "30px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  };
  function increament(val, id) {
    const newArray = meals.map((item) => {
      if (item.menuItemId == id) {
        item.quantity = item.quantity + val;
        return item;
      } else return item;
    });
    setMeals(newArray);
  }
  function decreament(val, id, type) {
    const newArray = meals.map((item) => {
      if (item.menuItemId == id) {
        if (item[type] > 0) {
          item[type] = item[type] - val;
        } else {
          item[type] = 0;
        }
        return item;
      } else return item;
    });
    setMeals(newArray);
  }
  function checkMaxMeals(id) {
    const maxMeals = [];
    totalMeals.forEach((meal) => {
      if (meal.oneWayQty + meal.returnQty > 0) {
        maxMeals.push(meal);
      }
    });
    if (!maxMeals.length) {
      return true;
    } else if (maxMeals.length <= maxMealsCount) {
      if (maxMeals.length == maxMealsCount) {
        let check = false;
        maxMeals.forEach((meal) => {
          if (meal.menuItemId == id) {
            check = true;
          }
        });
        return check;
      } else return true;
    } else {
      return false;
    }
  }
  function remove(id) {
    const newArray = meals.map((item) => {
      if (item.menuItemId == id) {
        item.quantity = 0;
        return item;
      } else return item;
    });
    setMeals(newArray);
  }
  return (
    <div className={`br-r-12 fade d-f f-col f-spb md-w-full smd-f-a-c smd-p-5px ${isRound ? "meal-round" : "meal"}`} style={{ overflow: "hidden", width: "232px", height: "fit-content", boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.25)" }}>
      <img src={food} alt="" width="100%" height="123px" />
      <div className="d-f f-spb f-col p-1 smd-f-row smd-f-a-c smd-p-0 mealDesWrapper" style={{ flexGrow: 1 }}>
        <div className="mealNameWrapper">
          <p style={{ height: "24px", overflow: "hidden" }}>{data.menuItemName}</p>
          <p className="b-500" style={{ fontFamily: "Arial, sans-serif" }}>
            {data.price.toLocaleString("en-GB", { style: "currency", currency: chargeCurrency })}
          </p>
        </div>
        <div className="m-a mt-1 smd-m-0" style={{ width: "fit-content" }}>
          {!data.quantity && !add && (
            <button onClick={() => setAdd(true)} className="bg-primary br-n white pointer mealAddBtn" style={style.btn}>
              Add
            </button>
          )}
          {(data.quantity || add) && (
            <>
              <div className="fade d-f f-a-c mealAddBtn2" style={{ justifyContent: "space-around", border: "1px solid var(--primaryColor)", width: "144px", height: "40px", borderRadius: "24px" }}>
                <button onClick={() => decreament(1, data.menuItemId)} className="pointer bg-primary white" style={style.roundBtn}>
                  -
                </button>
                <span>{data.quantity}</span>
                <button onClick={() => increament(1, data.menuItemId)} className="pointer bg-primary white" style={style.roundBtn}>
                  +
                </button>
              </div>
            </>
          )}
          {data.quantity > 0 && (
            <button onClick={() => remove(data.menuItemId)} className="bg-primary br-n white pointer mealAddBtn mt-4" style={style.btn}>
              Remove
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
