import { useEffect, useState } from "react";
import Loader from "../icons/Loader";
import { booking } from "../../api/book";
import { extraLuggagePrice, getExtraLuggage, bookExtraLuggage } from "../../api/api";
export default function ExtraLuggage({ weightEntitlement, setWeightEntilement, completedPaxes, setCompletedPaxes, totalExcessBuggage, setTotalExcessBuggage, proceedPaymentDts, data, setShow }) {
  const [submit, setSubmit] = useState(false);
  const [loader, setLoader] = useState(false);
  const maxWeight = weightEntitlement.maxLuggagePerson;
  const returnMaxWeight = weightEntitlement.maxLuggagePersonReturn;
  const isRound = data.round;
  const bookingId = proceedPaymentDts.bookingId;
  function handleChange(e, pax, weight, maxWeight, validateErr, setValidateErr) {
    const val = Number(e.target.value);
    if (val >= 0) {
      if (val <= maxWeight) {
        if (val % 1 != 0) {
          setValidateErr({ ...validateErr, [weight]: "Please enter integer value" });
        } else {
          pax[weight] = Number(e.target.value);
          setValidateErr({ ...validateErr, [weight]: "" });
        }
      } else {
        pax[weight] = Number(e.target.value);
        setValidateErr({ ...validateErr, [weight]: `Maximum shoul be ${maxWeight}` });
      }
    }
  }
  function handleSubmit() {
    setSubmit(true);
    bookExtraLuggage({ requestType: "", paxes: completedPaxes }).then((data) => {
      if (data) {
        setTotalExcessBuggage({ weight: data.totalExcessBuggage.weight, returnWeight: data.totalExcessBuggage.returnWeight, amount: data.totalExcessBuggage.amount, returnAmount: data.totalExcessBuggage.returnAmount });
        let totalWeight = 0;
        completedPaxes.forEach((pax) => (totalWeight += Number(pax.weight) + Number(pax.returnWeight)));
      }
      setSubmit(false);
      setTimeout(() => setShow(false), 2000);
    });
  }
  useEffect(() => {
    setLoader(true);
    getExtraLuggage({
      companyCode: bookingId[0],
      documentCode: bookingId[1],
      bookingYear: bookingId[2],
      bookingSerial: bookingId[3],
    }).then((data) => {
      if (data) {
        setCompletedPaxes(data.paxLuggageList);
        setWeightEntilement({
          availableLuggageTotal: data.weightEntitlement.availableLuggageTotal,
          availableLuggageTotalReturn: data.weightEntitlement.availableLuggageTotalReturn,
          maxLuggagePerson: data.weightEntitlement.maxLuggagePerPersion,
          maxLuggagePersonReturn: data.weightEntitlement.maxLuggagePerPersionReturn,
        });
        setLoader(false);
      }
    });
  }, []);
  return (
    <div className="p-c p-1 br-r-1" style={{ maxWidth: "900px", maxHeight: "90%", height: "fit-content", overflow: "scroll" }}>
      <div className="bg-white p-4 br-r-1" style={{ height: "fit-content" }}>
        <h1 className="fs-20px b-500 primary txt-a-c">Provide Extra Luggage Quantity</h1>
        <p className="gray mtb-1 txt-a-c">Enhance your journey by adding extra luggage. Specify the quantity you need for a more convenient and comfortable travel experience.</p>
        <div className="flex justify-end items-center">
          <p className="fs-1-5 mr-4">Total :</p>
          <div className="p-r br-r-12 p-1" style={{ background: "var(--inputGray)", width: "fit-content", minWidth: "121px", height: "68px" }}>
            {/* <span className="b-500 fs-1-5">{submit ? <Loader color="#DF7435" /> : data.oneWayData.chargeCurrency + " " + (lugInfo.oneWayAmount + lugInfo.returnAmount).toLocaleString()}</span> */}
            <span className="b-500 fs-1-5">{submit ? <Loader color="#DF7435" /> : (totalExcessBuggage.amount + totalExcessBuggage.returnAmount).toLocaleString("en-GB", { style: "currency", currency: data.oneWayData.chargeCurrency })}</span>
          </div>
        </div>
        <div className="flex justify-end pr-2 mt-4 mb-2">
          <p className="text-center font-medium" style={{ width: "172px" }}>
            {data.oneWayData.voyageFrom} - {data.oneWayData.voyageTo}
          </p>
          {isRound && (
            <p className="ml-4 text-center font-medium" style={{ width: "172px" }}>
              {data.oneWayData.voyageTo} - {data.oneWayData.voyageFrom}
            </p>
          )}
        </div>
        <div className="mb-2 flex flex-col">
          {!loader && completedPaxes.map((pax, index) => <LuggagePerPax maxWeight={maxWeight} returnMaxWeight={returnMaxWeight} from={data.oneWayData.voyageFrom} to={data.oneWayData.voyageTo} key={index} isRound={data.round} pax={pax} handleChange={handleChange} />)}
          {loader && <Loader color="#DF7435" />}
        </div>

        <div className="flex flex-end gap-x-4">
          <button
            onClick={() => {
              setShow(false);
            }}
            className="br-btn pointer"
            style={{ width: "171px" }}
          >
            Cancel
          </button>
          {/* <button onClick={handleSubmit} className="p-r btn pointer" style={{ opacity: `${validateErr.oneWay || validateErr.return || submit ? 0.4 : 1}` }} disabled={validateErr.oneWay || validateErr.return}>
            Confirm
          </button> */}
          <button onClick={handleSubmit} className="btn pointer">
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
}

function LuggagePerPax({ pax, handleChange, isRound, maxWeight, returnMaxWeight }) {
  const [validateErr, setValidateErr] = useState({ weight: "", returnWeight: "" });
  return (
    <>
      <p className="font-medium md-hidden overflow-hidden mb-2"> {pax.name || pax.paxNumber.seqNo}</p>
      <div className={`flex br-r-8 p-2 pl-4 justify-end md-justify-between items-center ${validateErr.weight || validateErr.returnWeight ? "mb-0" : "mb-4"}`} style={{ background: "var(--inputGray)" }}>
        <p className="font-medium hidden md-block overflow-hidden whitespace-nowrap mr-4"> {pax.name || pax.paxNumber.seqNo}</p>
        <div className="flex">
          <input type="number" defaultValue={pax.weight ? pax.weight : ""} min="0" step="1" name="oneWay" onChange={(e) => handleChange(e, pax, "weight", maxWeight, validateErr, setValidateErr)} placeholder="Extra Luggage(Kg)" className="text-end br-n br-r-8 w-full" style={{ maxWidth: "172px", height: "48px" }} />
          {isRound && <input type="number" step="1" defaultValue={pax.returnWeight ? pax.returnWeight : ""} min="0" name="return" onChange={(e) => handleChange(e, pax, "returnWeight", returnMaxWeight, validateErr, setValidateErr)} placeholder="Extra Luggage(Kg)" className="text-end br-n br-r-8 ml-4" style={{ width: "200px", height: "48px" }} />}
        </div>
      </div>
      <p className="red text-end text-xs pr-4">{validateErr.weight || validateErr.returnWeight}</p>
    </>
  );
}
