import { useState } from "react";
import { useMediaQuery } from "react-responsive";
import Loader from "../icons/Loader";
import Meal from "./Meal";
import Msg from "./Msg";
import { fetchApi } from "../../api/utils";
import OrderTray from "./OrderTray";

export default function Meals({ setPaxList, isMealsSelectionForRound, selectedMeal, selectedMenu, selectedSubMenu, setSelectedMenu, setSelectedSubMenu, filterMeals, meals, setMeals, maxMealsCount, menuType, subMenu, menuList, menuListReturn, currentPax, totalMeal, setTotalMeal, setMealTotalPrice, currentMealInfo, setCurrentMealInfo, chargeCurrency, totalMeals, setShow, isRound, mealInfo, setMealInfo, proceedPaymentDts }) {
  const isMob = useMediaQuery({ query: "(max-width:1024px)" });
  const [success, setSuccess] = useState(true);
  const [submit, setSubmit] = useState(false);
  const [showOrderTray, setShowOrderTray] = useState(false);
  const [msg, setMsg] = useState(false);
  const bookingId = proceedPaymentDts.bookingId;
  function handleConfirm() {
    console.log(setTotalMeal);
    let food = [];
    let returnFood = [];
    console.log(returnFood);
    setSubmit(true);
    setTimeout(() => {
      if (isRound) {
        menuList.forEach((item) => {
          if (item.quantity > 0) {
            food.push({ code: item.menuItemId, quantity: item.quantity });
          }
        });
        menuListReturn.forEach((item) => {
          if (item.quantity > 0) {
            returnFood.push({ code: item.menuItemId, quantity: item.quantity });
          }
        });
        if (isMealsSelectionForRound) {
          food = currentPax.food;
        } else {
          returnFood = currentPax.returnFood ? currentPax.returnFood : [];
        }
      } else {
        menuList.forEach((item) => {
          if (item.quantity > 0) {
            food.push({ code: item.menuItemId, quantity: item.quantity });
          }
        });
      }
      console.log(returnFood);
      console.log(food);
      fetchApi("/Menu/book-food", {
        body: {
          food: food ? food : [],
          returnFood: returnFood,
          paxNumber: {
            companyCode: bookingId[0],
            documentCode: bookingId[1],
            bookingYear: bookingId[2],
            bookingSerial: bookingId[3],
            seqNo: currentPax.paxNumber.seqNo,
          },
        },
        requestType: "string",
      }).then((data) => {
        if (data) {
          fetchApi("/Menu/get-food", {
            body: {
              companyCode: bookingId[0],
              documentCode: bookingId[1],
              bookingYear: bookingId[2],
              bookingSerial: bookingId[3],
            },
          }).then((data) => {
            if (data) {
              console.log(data);
              setPaxList(data.paxFoodList);
              setTotalMeal(data.totalMeal);
              filterMeals(currentPax);
            }
          });
        }
      });

      setShow(false);
      setSubmit(false);
    }, 2000);
  }
  function handleCancel() {
    const lists = isMealsSelectionForRound ? menuListReturn : menuList;
    lists.forEach((item) => (item.quantity = 0));
    // setMealInfo({
    //   oneWayAmount: mealInfo.oneWayAmount - currentMealInfo.oneWayAmount,
    //   roundWayAmount: mealInfo.roundWayAmount - currentMealInfo.roundWayAmount,
    // });
    // setCurrentMealInfo({ oneWayAmount: 0, roundWayAmount: 0 });
    setShow(false);
  }
  console.log(currentPax);
  console.log(isMealsSelectionForRound);
  return (
    <div id="meals" className="overflow-hidden bg-white p-c w-full smd-h-100 smd-d-f smd-f-col r-gap-1 br-r-1" style={{ maxWidth: "1110px", height: "fit-content" }}>
      <div className="p-1">
        {!isMob && (
          <div className="d-f c-gap-1 f-wrap r-gap-1">
            {menuType.length > 0 &&
              menuType.map((menuItem, index) => (
                <button
                  onClick={() => {
                    setSelectedMenu(menuItem.majorGroupCode);
                    const getSub = subMenu.find((item) => item.majorGroupCode == menuItem.majorGroupCode).subGroupCode;
                    setSelectedSubMenu(getSub);
                    filterMeals(currentPax, isMealsSelectionForRound, menuItem.majorGroupCode, getSub);
                  }}
                  className={`pointer mealBtn b-500 ${menuItem.majorGroupCode == selectedMenu ? "bg-primary white" : "input-gray"}`}
                  key={menuItem.majorGroupCode}
                >
                  {menuItem.majorGroupDesc}
                </button>
              ))}
          </div>
        )}
        {!isMob && (
          <div className="d-f c-gap-1 f-wrap r-gap-1 mt-1">
            {subMenu.length > 0 &&
              subMenu.map((item, index) => {
                if (item.majorGroupCode == selectedMenu) {
                  return (
                    <span
                      onClick={() => {
                        setSelectedSubMenu(item.subGroupCode);
                        filterMeals(currentPax, isMealsSelectionForRound, item.majorGroupCode, item.subGroupCode, totalMeals);
                      }}
                      className={`pointer b-500 ${selectedSubMenu == item.subGroupCode ? "primary" : "black"}`}
                      key={item.subGroupCode}
                    >
                      {item.subGroupDesc}
                    </span>
                  );
                }
              })}
          </div>
        )}
        {isMob && (
          <div className="d-f c-gap-1">
            <div className="w-full">
              <label htmlFor="" className="fs-12px">
                Category
              </label>
              <select
                className="search-input"
                onChange={(e) => {
                  setSelectedMenu(e.target.value);
                  const getSub = subMenu.find((item) => item.majorGroupCode == e.target.value).subGroupCode;
                  setSelectedSubMenu(getSub);
                  filterMeals(e.target.value, getSub, totalMeals);
                }}
              >
                {menuType.length > 0 &&
                  menuType.map((menuItem, index) => (
                    <option key={menuItem.majorGroupCode} value={menuItem.majorGroupCode}>
                      {menuItem.majorGroupDesc}
                    </option>
                  ))}
              </select>
            </div>
            <div className="w-full">
              <label htmlFor="" className="fs-12px">
                Sub Category
              </label>
              <select
                className="search-input"
                onChange={(e) => {
                  setSelectedSubMenu(e.target.value);
                  filterMeals(selectedMenu, e.target.value, totalMeals);
                }}
              >
                {subMenu.length > 0 &&
                  subMenu.map((item, index) => {
                    if (item.majorGroupCode == selectedMenu) {
                      return (
                        <option key={item.subGroupCode} value={item.subGroupCode}>
                          {item.subGroupDesc}
                        </option>
                      );
                    }
                  })}
              </select>
            </div>
          </div>
        )}
        <div className="flex items-end flex-col mt-1">
          <p className="primary b-500 pointer">name: {currentPax.paxNumber ? currentPax.name || currentPax.paxNumber.seqNo : ""}</p>
          {(currentPax.food || currentPax.returnFood) && (
            <p onClick={() => setShowOrderTray(true)} className="primary b-700 pointer mb-1" style={{ textDecoration: "underline" }}>
              Order Tray
            </p>
          )}
          <p className=" b-500 smd-m-a input-gray grow-0" style={{ padding: ".5rem", borderRadius: ".5rem" }}>
            Total Price: <span style={{ fontFamily: "Arial, sans-serif" }}> {totalMeal.amount.toLocaleString("en-GB", { style: "currency", currency: chargeCurrency })}</span>
          </p>
        </div>
        <div className="mt-1 smd-m-0 smd-f-grow" style={{ height: "400px", overflowY: "auto" }}>
          {/* {success && <Loader color="blue" />} */}
          <div className="d-f c-gap-8 r-gap-8 smd-r-gap-1 mb-2 f-wrap p-1 sm-p-1px">{meals.length > 0 && meals.map((item) => <Meal key={item.menuItemId} selectedMeal={selectedMeal} menuList={menuList} totalMeals={totalMeals} setMsg={setMsg} maxMealsCount={maxMealsCount} currentMealInfo={currentMealInfo} setCurrentMealInfo={setCurrentMealInfo} data={item} chargeCurrency={chargeCurrency} mealInfo={mealInfo} setMealInfo={setMealInfo} meals={meals} setMeals={setMeals} />)}</div>
        </div>
        <div className="txt-end mt-1 smd-d-f smd-c-gap-1 f-j-c smd-m-0">
          <button className="pointer br-btn mr-2 sm-m-0 " onClick={handleCancel}>
            Cancel
          </button>
          <button className="p-r pointer btn" style={{ opacity: submit ? 0.4 : 1 }} onClick={handleConfirm}>
            Confirm {submit && <Loader color="black" />}
          </button>
        </div>
        {msg && <Msg setMsg={setMsg} maxMealsCount={maxMealsCount} />}
      </div>
      {showOrderTray && (
        <div onClick={() => setShowOrderTray(false)} className="overlay">
          {" "}
          <OrderTray totalMeals={isMealsSelectionForRound ? menuListReturn : menuList} chargeCurrency={chargeCurrency} />
        </div>
      )}
    </div>
  );
}
