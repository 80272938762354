import { useState } from "react";
import { BookBtn } from "../btn";
import DateTimeCard from "../common/DateTimeCard";
import ViewDetails from "../common/ViewDetails";
export default function VoyageCard({ luggageInfo, data, book, setBook, type, setShowReturn, isRound }) {
  const [view, setView] = useState(false);
  const style = {
    container: {
      width: "976px",
      borderRadius: "16px",
      boxShadow: "0px 0px 8px 0px rgba(0, 0, 0, 0.25)",
      padding: "0 3rem",
      position: "relative",
    },
    codeBatch: {
      width: "95px",
      height: "24px",
      backgroundColor: "var(--primaryColor)",
      borderRadius: "0 0 4px 4px",
      color: "white",
      fontWeight: 500,
      textAlign: "center",
    },
    amountBatch: {
      width: "112px",
      backgroundColor: "#D9D9D9",
      display: "flex",
      flexFlow: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    seatBatch: {
      width: "fit-content",
      backgroundColor: "#ec9e39",
      position: "absolute",
      top: 0,
      right: "3rem",
      padding: "0 .5rem",
    },
  };
  return (
    <div style={style.container} className="voyage-card fade">
      <div className="d-f f-spb" style={{ height: "158px" }}>
        <div className="d-f f-col f-spb pb-1">
          <div style={style.codeBatch}>{data.voyageId}</div>
          <DateTimeCard fs={{ date: "fs-14px", time: "fs-1-5" }} data={data} />
          <span style={{ color: "var(--primaryColor)", textDecoration: "underline", cursor: "pointer" }} onClick={() => setView(!view)}>
            {view ? "Close details" : "View details"}
          </span>
        </div>
        <div className="d-f f-a-c">
          <span>Class:</span>
          <span className="b-700">&nbsp;{data.className}</span>
        </div>
        <div style={style.amountBatch}>
          <span>{book.chargeCurrency}</span>
          <span className="fs-1-5 b-600">{data.totalChargeAmount.toLocaleString()}</span>
        </div>
        <div className="d-f f-a-c">
          <span className="fs-14px b-500" style={style.seatBatch}>
            {data.availableSeat <= 10 ? data.availableSeat + " Seats Left" : ""}
          </span>
          <BookBtn luggageInfo={luggageInfo} isRound={isRound} book={book} setBook={setBook} type={type} id={data.voyageId} data={data} setShowReturn={setShowReturn}>
            Book
          </BookBtn>
        </div>
      </div>
      {view && <ViewDetails maxLuggage={data.maxLuggage} />}
    </div>
  );
}
